import {useLazyQuery} from '@apollo/client';
import uniqueId from 'lodash/uniqueId';
import {useRef, useEffect} from 'react';
import {useSetRecoilState} from 'recoil';
import {errorState} from '../../components/ErrorStateSnackbar';
import useMessage from '../useMessage';
import useProgress from '../useProgress';

export default function useLazyQueryFHG(query, options) {
   const theUniqueId = useRef(uniqueId()).current;
   const [, /*Unused*/ setProgress] = useProgress(theUniqueId);
   const type = useMessage(query?.typeKey, 'Unknown');
   const setErrorState = useSetRecoilState(errorState);

   const [queryFunction, result] = useLazyQuery(query?.query, options);
   // const setProgressGlobal = useSetRecoilState(progressGlobal);
   const resolveRef = useRef();

   useEffect(() => {
      if ((result.called || result.data) && !result.loading && resolveRef.current) {
         resolveRef.current(result);
         resolveRef.current = undefined;
      }
   }, [result]);

   // useEffect(() => {
   //    if (showLoading) {
   //       setProgressGlobal(showGlobalProgress);
   //    }
   //    return () => {
   //       setProgressGlobal(true);
   //    };
   // }, [showGlobalProgress, setProgressGlobal, showLoading]);

   useEffect(() => {
      return () => {
         setProgress(false);
      };
   }, [setProgress]);

   // useEffect(() => {
   //    if (showLoading) {
   //       setProgress(result?.loading);
   //    }
   // }, [result?.loading, setProgress, showLoading]);

   useEffect(() => {
      if (result.error) {
         console.log('Error type', query.typeKey);
         console.log(result.error, result.error.stackTrace);
         setErrorState({error: result.error, errorKey: 'load.error', values: {type, message: result.error.message}});
      }
   }, [result?.error, setErrorState, query?.typeKey, type]);

   // const localQueryFunction = useCallback(
   //    async (variables, context) => {
   //       await queryFunction(variables, context);
   //
   //       return new Promise((resolve) => {
   //          resolveRef.current = resolve;
   //       });
   //    },
   //    [queryFunction]
   // );

   return [queryFunction, result];
}

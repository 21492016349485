import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import {formatMessage} from '../utils/Utils';
import {useEffect} from 'react';

/**
 * The component used as a placeholder for not implemented components.
 *
 * Reviewed:
 */
export default function usePageTitle({title, titleKey, values}) {
   const intl = useIntl();

   useEffect(() => {
      const appTitle = formatMessage(intl, 'application.title', '');
      const titleLabel = titleKey ? formatMessage(intl, titleKey, title, values) : title || '';
      document.title = appTitle ? `${appTitle} - ${titleLabel}` : titleLabel;
   }, [title, titleKey, values, intl]);
}

usePageTitle.propTypes = {
   title: PropTypes.string,
   titleKey: PropTypes.string,
   values: PropTypes.any,
};

import gql from 'graphql-tag';
import {UNDELETE_ACTION, CREATE_UPDATE_ACTION, DELETE_ACTION} from '../fhg/hooks/data/useMutationFHG';
import {
   CODE_MODIFICATION_PATH_FRAGMENT,
   REPORT_TEMPLATE_FRAGMENT,
   REPORT_HISTORY_FRAGMENT,
   CODE_INFORMATION_SEARCH_FRAGMENT,
   ADDRESS_FRAGMENT,
   CODE_EDITION_FRAGMENT,
   CODE_INFORMATION_FRAGMENT,
   CODE_MODIFICATION_FRAGMENT,
   CONTACT_FRAGMENT,
   JURISDICTION_FRAGMENT,
   LOCAL_JURISDICTION_FRAGMENT,
   WEBSITE_FRAGMENT,
   CODE_TITLE_FRAGMENT,
   AUTHORITY_FRAGMENT,
   CITY_FRAGMENT,
   STATE_FRAGMENT,
   USER_FRAGMENT,
   CODE_TYPE_FRAGMENT,
   AUTHORITY_SEARCH_FRAGMENT,
   USER_SEARCH_FRAGMENT,
   USER_FAVOURITE_FRAGMENT,
   CODE_INFORMATION_LX_FRAGMENT,
} from './FragmentsGL';

/**
 * ONE-TO-MANY:
 * Jurisdiction -> LocalJurisdiction
 * @type {DocumentNode}
 */
export const JURISDICTION_ALL_QUERY = {
   query: gql`
      query getJurisdictionAll($offset: Int, $limit: Int) {
         jurisdictions: jurisdiction_AllWhere(
            limit: $limit
            offset: $offset
            sortOrder: [{fieldName: "name", direction: "ASC"}]
         ) {
            ...JurisdictionInfo
         }
      }
      ${JURISDICTION_FRAGMENT}
   `,
   typeKey: 'jurisdiction.type',
};

export const JURISDICTION_ALL_WHERE_QUERY = {
   query: gql`
      query getJurisdictionAllWhere($id: [UUID]) {
         jurisdictions: jurisdiction_AllWhere(jurisdictionSearch: {id: $id}) {
            ...JurisdictionInfo
         }
      }
      ${JURISDICTION_FRAGMENT}
   `,
   typeKey: 'jurisdiction.type',
   path: 'jurisdiction',
};

export const JURISDICTION_ID_QUERY = {
   query: gql`
      query getJurisdictionById($id: UUID!) {
         jurisdiction: jurisdiction_ById(jurisdictionId: $id) {
            ...JurisdictionInfo
         }
      }
      ${JURISDICTION_FRAGMENT}
   `,
   typeKey: 'jurisdiction.type',
   path: 'jurisdiction',
};

// export const JURISDICTION_COUNT = {
//    query: gql`
//       query getJurisdictionCount {
//          jurisdiction_Count(includeDeleted: false)
//       }
//    `,
// };

// export const LOCAL_JURISDICTION_BY_ID_QUERY = gql`
//    query getLocalJurisdictionBYID($localJurisdictionId: UUID!) {
//       localJurisdiction: localJurisdiction_ById(localJurisdictionId: $localJurisdictionId) {
//          id
//          jurisdictionId
//          name
//       }
//    }
// `;

export const LOCAL_JURISDICTION_ALL_WHERE_QUERY = {
   query: gql`
      query getLocalJurisdictionAllWhere($id: [UUID], $jurisdictionId: [UUID]) {
         localJurisdiction: localJurisdiction_AllWhere(
            localJurisdictionSearch: {id: $id, jurisdictionId: $jurisdictionId}
         ) {
            ...localJurisdictionInfo
         }
      }
      ${LOCAL_JURISDICTION_FRAGMENT}
   `,
   typeKey: 'local.type',
   path: 'localJurisdiction',
};

export const LOCAL_JURISDICTION_CREATE_UPDATE = {
   mutation: gql`
      mutation localJurisdictionCreateUpdate($id: UUID!, $jurisdictionId: UUID!, $name: String!) {
         localJurisdiction: localJurisdiction_CreateUpdate(
            localJurisdiction: {id: $id, jurisdictionId: $jurisdictionId, name: $name}
         ) {
            id
            jurisdictionId
            name
         }
      }
   `,
   typeKey: 'localJurisdiction.type',
   path: 'localJurisdiction',
   resultType: 'LocalJurisdiction',
   actionKey: CREATE_UPDATE_ACTION,
   updateQueries: (variables) => {
      return [{...LOCAL_JURISDICTION_ALL_WHERE_QUERY, variables}];
   },
};

export const LOCAL_JURISDICTION_DELETE = {
   mutation: gql`
      mutation localJurisdictionDelete($id: UUID!) {
         localJurisdiction_Delete(localJurisdictionId: $id)
      }
   `,
   typeKey: 'localJurisdiction.type',
   actionKey: DELETE_ACTION,
   updateQueries: (variables) => {
      return [{...LOCAL_JURISDICTION_ALL_WHERE_QUERY, variables}];
   },
};

export const CODE_TYPE_ALL_QUERY = {
   query: gql`
      query getCodeTypeAll {
         codeTypes: codeType_All {
            id
            name
            label: name
            isGenericType
            genericTypeId
            genericType {
               id
               name
            }
            types {
               id
               name
            }
         }
      }
   `,
   typeKey: 'codeType.type',
   path: 'codeTypes',
};

// export const CODE_TYPE_BY_ID_QUERY = {
//    query: gql`
//       query getCodeTypeById($codeTypeId: UUID!) {
//          codeType: codeType_ById(codeTypeId: $codeTypeId) {
//             ...codeTypeInfo
//          }
//       }
//       ${CODE_TYPE_FRAGMENT}
//    `,
//    typeKey: 'codeType.type',
//    path: 'codeType',
// };

export const CODE_TYPE_ALL_WHERE_QUERY = {
   query: gql`
      query getCodeTypeAllWhere($id: [UUID], $isGenericType: [Boolean], $genericTypeId: [UUID]) {
         codeTypes: codeType_AllWhere(
            codeTypeSearch: {id: $id, isGenericType: $isGenericType, genericTypeId: $genericTypeId}
            sortOrder: [{fieldName: "name", direction: "ASC"}]
         ) {
            ...codeTypeInfo
         }
      }
      ${CODE_TYPE_FRAGMENT}
   `,
   typeKey: 'codeType.type',
   path: 'codeTypes',
};

export const CODE_TYPE_CREATE_UPDATE = {
   mutation: gql`
      mutation codeTypeCreateUpdate($id: UUID!, $name: String, $genericTypeId: UUID) {
         codeType: codeType_CreateUpdate(codeType: {id: $id, name: $name, genericTypeId: $genericTypeId}) {
            id
            name
            isGenericType
            genericTypeId
         }
      }
   `,
   typeKey: 'codeType.type',
   resultType: 'CodeType',
   path: 'codeType',
   actionKey: CREATE_UPDATE_ACTION,
   updateQueries: () => [{...CODE_TYPE_ALL_WHERE_QUERY}, {...CODE_TYPE_ALL_QUERY}],
};

export const CODE_TYPE_DELETE = {
   mutation: gql`
      mutation codeTypeDelete($id: UUID!) {
         codeType_Delete(codeTypeId: $id)
      }
   `,
   typeKey: 'codeType.type',
   path: 'codeType_Delete',
   actionKey: DELETE_ACTION,
   updateQueries: () => [{...CODE_TYPE_ALL_QUERY}, {...CODE_TYPE_ALL_WHERE_QUERY}],
};

/**
 * Has two addresses. A separate one for mailing address if needed.
 *
 * It would be nice to be able to pass codeTypeId because that is how my filters work but that would mean pulling all
 * the codeInformation to find out what code types are supported.
 *
 * ONE-TO-MANY RELATIONSHIPS:
 *    Authority -> CodeInformation
 *    Authority -> Contact
 *    Authority -> WebSite
 * @type {DocumentNode}
 */
export const AUTHORITY_ALL_WHERE_QUERY = {
   query: gql`
      query getAuthorityAllWhere($id: [UUID], $localJurisdictionId: [UUID], $codeTypeId: [UUID]) {
         authority: authority_AllWhere(
            authoritySearch: {localJurisdictionId: $localJurisdictionId, codeTypeId: $codeTypeId, id: $id}
         ) {
            ...authorityInfo
         }
      }
      ${AUTHORITY_FRAGMENT}
   `,
   typeKey: 'authority.type',
};

// export const AUTHORITY_BY_ID_QUERY = {
//    query: gql`
//       query getAuthorityById($authorityId: UUID!) {
//          authority: authority_ById(authorityId: $authorityId) {
//             ...authorityInfo
//          }
//       }
//       ${AUTHORITY_FRAGMENT}
//    `,
//    typeKey: 'authority.type',
// };

// export const ADDRESS_ALL_WHERE_QUERY = {
//    query: gql`
//       query getAddressAll($localJurisdictionId: [UUID], $codeTypeId: [UUID]) {
//          address: address_AllWhere(
//             addressSearch: {localJurisdictionId: $localJurisdictionId, codeTypeId: $codeTypeId}
//          ) {
//             id
//             #not sure if name is needed
//             name
//             addressLineOne
//             addressLineTwo
//             cityId
//             stateId
//             zipCode
//          }
//       }
//    `,
//    typeKey: 'address.type',
//    path: 'address',
// };

export const ADDRESS_BY_ID_QUERY = {
   query: gql`
      query getAddressById($addressId: UUID!) {
         address: address_ById(addressId: $addressId) {
            ...addressInfo
         }
      }
      ${ADDRESS_FRAGMENT}
   `,
   typeKey: 'address.type',
   path: 'address',
};

export const ADDRESS_CREATE_UPDATE = {
   mutation: gql`
      mutation addressCreateUpdate(
         $id: UUID!
         $name: String
         $addressLineOne: String
         $addressLineTwo: String
         $cityId: UUID
         $stateId: UUID
         $zipCode: String
      ) {
         address: address_CreateUpdate(
            address: {
               id: $id
               name: $name
               addressLineOne: $addressLineOne
               addressLineTwo: $addressLineTwo
               cityId: $cityId
               stateId: $stateId
               zipCode: $zipCode
            }
         ) {
            ...addressInfo
         }
      }
      ${ADDRESS_FRAGMENT}
   `,
   typeKey: 'address.type',
   resultType: 'Address',
   path: 'address',
   actionKey: CREATE_UPDATE_ACTION,
   updateQueries: () => [
      // {...ADDRESS_BY_ID_QUERY, variables},
      // {...ADDRESS_ALL_WHERE_QUERY, variables},
   ],
};

export const AUTHORITY_CREATE_UPDATE = {
   mutation: gql`
      mutation AuthorityCreateUpdate(
         $id: UUID!
         $name: String
         $codeTypeIdList: [UUID]
         $localJurisdictionId: UUID
         $addressId: UUID
         $mailingAddressId: UUID
      ) {
         authority: authority_CreateUpdate(
            authority: {
               id: $id
               name: $name
               codeTypeIdList: $codeTypeIdList
               localJurisdictionId: $localJurisdictionId
               addressId: $addressId
               mailingAddressId: $mailingAddressId
            }
         ) {
            ...authorityInfo
         }
      }
      ${AUTHORITY_FRAGMENT}
   `,
   typeKey: 'authority.type',
   resultType: 'Authority',
   path: 'authority',
   actionKey: CREATE_UPDATE_ACTION,
   updateQueries: (variables) => [
      {...AUTHORITY_ALL_WHERE_QUERY, variables},
      // {...AUTHORITY_ALL_WHERE_QUERY, variables: {localJurisdictionId: variables?.localJurisdictionId}},
   ],
};

export const AUTHORITY_DELETE = {
   mutation: gql`
      mutation authorityDelete($id: UUID!) {
         authority_Delete(authorityId: $id)
      }
   `,
   typeKey: 'authority.type',
   path: 'authority',
   actionKey: DELETE_ACTION,
   updateQueries: (variables) => [{...AUTHORITY_ALL_WHERE_QUERY, variables}],
};

/**
 * ONE-TO-MANY
 * codeInformation -> codeModifications
 * @type {DocumentNode}
 */
export const CODE_INFORMATION_LX_ALL_WHERE_QUERY = {
   query: gql`
      query getCodeInformationLxAllWhere(
         $id: [UUID]
         $localJurisdictionId: [UUID]
         $codeEditionId: [UUID]
         $codeTypeId: [UUID]
      ) {
         codeInformation: codeInformation_AllWhere(
            codeInformationSearch: {
               id: $id
               localJurisdictionId: $localJurisdictionId
               codeTypeId: $codeTypeId
               codeEditionId: $codeEditionId
            }
         ) {
            ...codeInformationLxInfo
         }
      }
      ${CODE_INFORMATION_LX_FRAGMENT}
   `,
   typeKey: 'codeType.type',
   path: 'codeInformation',
};

export const CODE_INFORMATION_ALL_WHERE_QUERY = {
   query: gql`
      query getCodeInformationAllWhere(
         $id: [UUID]
         $localJurisdictionId: [UUID]
         $codeEditionId: [UUID]
         $codeTypeId: [UUID]
      ) {
         codeInformation: codeInformation_AllWhere(
            codeInformationSearch: {
               id: $id
               localJurisdictionId: $localJurisdictionId
               codeTypeId: $codeTypeId
               codeEditionId: $codeEditionId
            }
         ) {
            ...codeInformationInfo
         }
      }
      ${CODE_INFORMATION_FRAGMENT}
   `,
   typeKey: 'codeType.type',
   path: 'codeInformation',
};

export const CODE_INFORMATION_CREATE_UPDATE = {
   mutation: gql`
      mutation codeInformationCreateUpdate(
         $id: UUID!
         $localJurisdictionId: UUID
         $name: String
         $codeEditionId: UUID
         $comment: String
         $effectiveDate: DateOnly
         $pendingEffectiveDate: DateOnly
         $attachmentFileS3Data: [FileS3Data]
      ) {
         codeInformation: codeInformation_CreateUpdate(
            codeInformation: {
               id: $id
               localJurisdictionId: $localJurisdictionId
               codeEditionId: $codeEditionId
               comment: $comment
               effectiveDate: $effectiveDate
               pendingEffectiveDate: $pendingEffectiveDate
               name: $name
               attachmentFileS3Data: $attachmentFileS3Data
            }
         ) {
            ...codeInformationInfo
         }
      }
      ${CODE_INFORMATION_FRAGMENT}
   `,
   typeKey: 'codeInformation.type',
   resultType: 'CodeInformation',
   path: 'codeInformation',
   actionKey: CREATE_UPDATE_ACTION,
   updateQueries: (variables) => [
      {...CODE_INFORMATION_ALL_WHERE_QUERY, variables},
      {...CODE_INFORMATION_LX_ALL_WHERE_QUERY, variables},
   ],
};

export const CODE_INFORMATION_DELETE = {
   mutation: gql`
      mutation codeInformationDelete($id: UUID!) {
         codeInformation_Delete(codeInformationId: $id)
      }
   `,
   typeKey: 'codeInformation.type',
   actionKey: DELETE_ACTION,
   updateQueries: (variables) => [{...CODE_INFORMATION_ALL_WHERE_QUERY, variables}],
};

export const CODE_INFORMATION_ATTACHMENT_DELETE = {
   mutation: gql`
      mutation codeInformationAttachmentDelete($codeInformationId: UUID!, $index: Int!) {
         codeInformation: codeInformation_DeleteAttachmentFile(codeInformationId: $codeInformationId, index: $index) {
            ...codeInformationInfo
         }
      }
      ${CODE_INFORMATION_FRAGMENT}
   `,
   typeKey: 'codeInformation.type',
   actionKey: DELETE_ACTION,
   updateQueries: (variables) => [{...CODE_INFORMATION_ALL_WHERE_QUERY, variables}],
};

/**
 * Has two addresses. A separate one for mailing address if needed.
 *
 * It would be nice to be able to pass codeTypeId because that is how my filters work but that would mean pulling all
 * the codeInformation to find out what code types are supported.
 *
 * ONE-TO-MANY RELATIONSHIPS:
 *    Authority -> CodeInformation
 *    Authority -> Contact
 *    Authority -> WebSite
 * @type {DocumentNode}
 */
export const CODE_MODIFICATION_ALL_WHERE_QUERY = {
   query: gql`
      query getCodeModificationAllWhere(
         $codeInformationId: [UUID]
         $isPending: [Boolean]
         $requirement: [String]
         $requirementSearch: String
      ) {
         codeModifications: codeModification_AllWhere(
            codeModificationSearch: {
               codeInformationId: $codeInformationId
               isPending: $isPending
               requirement: $requirement
               requirementSearch: $requirementSearch
            }
            sortOrder: [{fieldName: "updatedDateTime", direction: "DESC"}]
         ) {
            ...codeModificationInfo
         }
      }
      ${CODE_MODIFICATION_FRAGMENT}
   `,
   typeKey: 'codeModification.type',
   path: 'codeModifications',
};

// export const CODE_MODIFICATION_BY_ID_QUERY = {
//    query: gql`
//       query getCodeModificationById($codeModificationId: UUID!) {
//          codeModification: codeModification_ById(codeModificationId: $codeModificationId) {
//             ...codeModificationInfo
//          }
//       }
//       ${CODE_MODIFICATION_FRAGMENT}
//    `,
//    typeKey: 'codeModification.type',
//    path: 'codeModification',
// };

export const CODE_MODIFICATION_CREATE_UPDATE = {
   mutation: gql`
      mutation codeModificationCreateUpdate(
         $id: UUID!
         $title: String
         $codeInformationId: UUID
         $effectiveDate: DateOnly
         $requirement: String
         $comment: String
         $statusId: UUID
         $isPending: Boolean
         $modifiedText: String
         $originalText: String
      ) {
         codeModification: codeModification_CreateUpdate(
            codeModification: {
               id: $id
               title: $title
               codeInformationId: $codeInformationId
               effectiveDate: $effectiveDate
               requirement: $requirement
               statusId: $statusId
               comment: $comment
               isPending: $isPending
               modifiedText: $modifiedText
               originalText: $originalText
            }
         ) {
            ...codeModificationInfo
         }
      }
      ${CODE_MODIFICATION_FRAGMENT}
   `,
   typeKey: 'codeModification.type',
   resultType: 'CodeModification',
   path: 'codeModification',
   actionKey: CREATE_UPDATE_ACTION,
   updateQueries: (variables) => [{...CODE_MODIFICATION_ALL_WHERE_QUERY, variables}],
};

export const CODE_MODIFICATION_DELETE = {
   mutation: gql`
      mutation codeModificationDelete($id: UUID!) {
         codeModification_Delete(codeModificationId: $id)
      }
   `,
   typeKey: 'codeModification.type',
   resultType: 'codeModification_Delete',
   path: 'codeModification_Delete',
   actionKey: DELETE_ACTION,
   updateQueries: (variables) => [{...CODE_MODIFICATION_ALL_WHERE_QUERY, variables}],
};

/**
 * WebsiteTypes by default are: Primary, Regulations, Licensing Requirements, Fees, Program, and Other.
 *
 * @type {DocumentNode}
 */
export const WEBSITES_ALL_WHERE_QUERY = {
   query: gql`
      query websiteAllWhere($authorityId: [UUID], $websiteTypeId: [UUID]) {
         websites: website_AllWhere(websiteSearch: {authorityId: $authorityId, websiteTypeId: $websiteTypeId}) {
            ...websiteInfo
         }
      }
      ${WEBSITE_FRAGMENT}
   `,
   typeKey: 'website.type',
   path: 'websites',
};

export const WEBSITES_CREATE_UPDATE = {
   mutation: gql`
      mutation websiteCreateUpdate($id: UUID!, $name: String, $url: String, $websiteTypeId: UUID, $authorityId: UUID) {
         websites: website_CreateUpdate(
            website: {id: $id, name: $name, url: $url, websiteTypeId: $websiteTypeId, authorityId: $authorityId}
         ) {
            ...websiteInfo
         }
      }
      ${WEBSITE_FRAGMENT}
   `,
   typeKey: 'website.type',
   resultType: 'Website',
   path: 'websites',
   actionKey: CREATE_UPDATE_ACTION,
   updateQueries: (variables) => [{...WEBSITES_ALL_WHERE_QUERY, variables}],
};

/**
 * ONE-TO-MANY:
 * codeTitle -> codeEdition
 * @type {DocumentNode}
 */
export const CODE_TITLE_ALL_WHERE_QUERY = {
   query: gql`
      query getCodeTitleAllWhere($codeTitleId: [UUID], $codeTypeId: [UUID]) {
         codeTitles: codeTitle_AllWhere(
            codeTitleSearch: {codeTypeId: $codeTypeId, id: $codeTitleId}
            sortOrder: [{fieldName: "name", direction: "ASC"}]
         ) {
            ...codeTitleInfo
         }
      }
      ${CODE_TITLE_FRAGMENT}
   `,
   typeKey: 'codeTitle.type',
   path: 'codeTitles',
};

export const CODE_TITLE_BY_ID_QUERY = {
   query: gql`
      query getCodeTitleById($codeTitleId: UUID!) {
         codeTitle: codeTitle_ById(codeTitleId: $codeTitleId) {
            ...codeTitleInfo
         }
      }
      ${CODE_TITLE_FRAGMENT}
   `,
   typeKey: 'codeTitle.type',
   path: 'codeTitle',
};

export const CODE_TITLE_CREATE_UPDATE = {
   mutation: gql`
      mutation codeTitleCreateUpdate($id: UUID!, $codeTypeId: UUID!, $name: String) {
         codeTitle: codeTitle_CreateUpdate(codeTitle: {id: $id, name: $name, codeTypeId: $codeTypeId}) {
            ...codeTitleInfo
         }
      }
      ${CODE_TITLE_FRAGMENT}
   `,
   typeKey: 'codeTitle.type',
   path: 'codeTitle',
   resultType: 'CodeTitle',
   actionKey: CREATE_UPDATE_ACTION,
   updateQueries: (variables) => [{...CODE_TITLE_ALL_WHERE_QUERY, variables}],
};

export const CODE_TITLE_DELETE = {
   mutation: gql`
      mutation codeTitleDelete($id: UUID!) {
         codeTitle_Delete(codeTitleId: $id)
      }
   `,
   typeKey: 'codeTitle.type',
   path: 'codeTitle_Delete',
   actionKey: DELETE_ACTION,
   updateQueries: (variables) => [{...CODE_TITLE_ALL_WHERE_QUERY, variables}],
};

export const CODE_EDITION_ALL_WHERE_QUERY = {
   query: gql`
      query getCodeEditionAllWhere($id: [UUID], $codeTitleId: [UUID]) {
         codeEditions: codeEdition_AllWhere(
            codeEditionSearch: {id: $id, codeTitleId: $codeTitleId}
            sortOrder: [{fieldName: "name", direction: "ASC"}]
         ) {
            ...codeEditionInfo
         }
      }
      ${CODE_EDITION_FRAGMENT}
   `,
   typeKey: 'codeEdition.type',
   path: 'codeEditions',
};

export const CODE_EDITION_BY_ID_QUERY = {
   query: gql`
      query getCodeEditionById($codeEditionId: UUID!) {
         codeEdition: codeEdition_ById(codeEditionId: $codeEditionId) {
            ...codeEditionInfo
         }
      }
      ${CODE_EDITION_FRAGMENT}
   `,
   typeKey: 'codeEdition.type',
   path: 'codeEditions',
};

export const CODE_EDITION_CREATE_UPDATE = {
   mutation: gql`
      mutation codeEditionCreateUpdate($id: UUID!, $codeTitleId: UUID!, $name: String) {
         codeEdition: codeEdition_CreateUpdate(codeEdition: {id: $id, name: $name, codeTitleId: $codeTitleId}) {
            ...codeEditionInfo
         }
      }
      ${CODE_EDITION_FRAGMENT}
   `,
   typeKey: 'codeEdition.type',
   resultType: 'CodeEdition',
   path: 'codeEdition',
   actionKey: CREATE_UPDATE_ACTION,
   updateQueries: (variables) => [{...CODE_EDITION_ALL_WHERE_QUERY, variables}],
};

export const CODE_EDITION_DELETE = {
   mutation: gql`
      mutation codeEditionDelete($id: UUID!) {
         codeEdition_Delete(codeEditionId: $id)
      }
   `,
   typeKey: 'codeEdition.type',
   path: 'codeEdition_Delete',
   actionKey: DELETE_ACTION,
   updateQueries: (variables) => [{...CODE_EDITION_ALL_WHERE_QUERY, variables}],
};

// export const TYPE_ALL_QUERY = {
//    query: gql`
//       query getTypeAll {
//          types: type_All {
//             id
//             category
//             name
//          }
//       }
//    `,
//    typeKey: 'type.type',
//    path: 'types',
// };

export const TYPE_ALL_WHERE_QUERY = {
   query: gql`
      query getTypeWhereAll($id: [UUID], $category: [String]) {
         types: type_AllWhere(typeSearch: {id: $id, category: $category}) {
            id
            category
            name
         }
      }
   `,
   typeKey: 'type.type',
   queryPath: 'types',
};

// export const TYPE_CREATE_UPDATE = {
//    mutation: gql`
//       mutation typeCreateUpdate($id: UUID!, $category: String, $name: String) {
//          type: type_CreateUpdate(type: {id: $id, category: $category, name: $name}) {
//             ...typeInfo
//          }
//       }
//       ${TYPE_FRAGMENT}
//    `,
//    typeKey: 'type.type',
//    resultType: 'Type',
//    path: 'type',
//    actionKey: CREATE_UPDATE_ACTION,
//    updateQueries: (variables) => [{...TYPE_ALL_WHERE_QUERY, variables}],
// };

// export const TYPE_DELETE = gql`
//    mutation typeDelete($typeId: UUID!) {
//       type_Delete(typeId: $typeId)
//    }
// `;

/**
 * For now, I only have one task like this that I am planning, but there could potentially be more in the future.
 * @type {DocumentNode}
 */
export const TASK_ALL_QUERY = {
   query: gql`
      query getTaskAll {
         tasks: task_All {
            id
            name
            urlSearchParams
         }
      }
   `,
   typeKey: 'task.type',
   path: 'tasks',
};

// query getJurisdictionAll {
//    jurisdictions: jurisdictions {
//       ...JurisdictionInfo
//    }
// }
// ${JURISDICTION_FRAGMENT}

// USERS
// export const USER_ALL_QUERY = {
//    query: gql`
//       query getAllUser($includeDeleted: Boolean) {
//          users: user_All(includeDeleted: $includeDeleted) {
//             ...userInfo
//          }
//       }
//       ${USER_FRAGMENT}
//    `,
//    path: 'users',
//    typeKey: 'user.type',
// };

export const USERS_ALL_WHERE_QUERY = {
   query: gql`
      query getUsersAllWhere(
         $roleId: [UUID]
         $contactName: [String]
         $username: [String]
         $cognitoSub: [String]
         $isDeleted: [Boolean]
      ) {
         users: user_AllWhere(
            userSearch: {
               contactName: $contactName
               username: $username
               cognitoSub: $cognitoSub
               isDeleted: $isDeleted
               roleId: $roleId
            }
         ) {
            ...userInfo
         }
      }
      ${USER_FRAGMENT}
   `,
   path: 'users',
   typeKey: 'user.type',
};

export const USER_BY_ID_QUERY = {
   query: gql`
      query getUserById($id: UUID!) {
         user: user_ById(userId: $id) {
            ...userInfo
         }
      }
      ${USER_FRAGMENT}
   `,
   path: 'user',
   typeKey: 'user.type',
};
export const USER_BY_COGNITO_SUB_QUERY = {
   query: gql`
      query getUserByCognitoSub($cognitoSub: [String!]) {
         users: user_AllWhere(userSearch: {cognitoSub: $cognitoSub}) {
            ...userInfo
         }
      }
      ${USER_FRAGMENT}
   `,
   path: 'user',
   typeKey: 'user.type',
};

export const USER_CREATE_UPDATE = {
   mutation: gql`
      mutation userCreateUpdate(
         $id: UUID!
         $roleId: UUID
         $email: String
         $username: String
         $contactName: String
         $password: String
         $phonePrimary: String
         $phoneSecondary: String
         $addressLineOne: String
         $addressLineTwo: String
         $cityId: UUID
         $stateId: UUID
         $zipCode: Int
         $noOfSearches: Int
         $eulaConfirmed: Boolean
      ) {
         user: user_CreateUpdate(
            user: {
               id: $id
               roleId: $roleId
               email: $email
               username: $username
               contactName: $contactName
               password: $password
               phonePrimary: $phonePrimary
               phoneSecondary: $phoneSecondary
               addressLineOne: $addressLineOne
               addressLineTwo: $addressLineTwo
               cityId: $cityId
               stateId: $stateId
               zipCode: $zipCode
               noOfSearches: $noOfSearches
               eulaConfirmed: $eulaConfirmed
            }
         ) {
            ...userInfo
         }
      }
      ${USER_FRAGMENT}
   `,
   typeKey: 'user.type',
   actionKey: CREATE_UPDATE_ACTION,
   updateQueries: (variables) => [{...USERS_ALL_WHERE_QUERY, variables}],
};

export const USER_DELETE = {
   mutation: gql`
      mutation userDelete($id: UUID!) {
         user_Delete(userId: $id)
      }
   `,
   typeKey: 'user.type',
   path: 'user_Delete',
   actionKey: DELETE_ACTION,
   updateQueries: (variables) => [{...USERS_ALL_WHERE_QUERY, variables}],
};

export const USER_UNDELETE = {
   mutation: gql`
      mutation userUndelete($id: UUID!) {
         user_UnDelete(userId: $id)
      }
   `,
   typeKey: 'user.type',
   actionKey: UNDELETE_ACTION,
};

// export const getUserCacheQueries = (variables) => {
//    return [
//       {query: USER_ALL_QUERY, queryPath: 'users'},
//       {query: USERS_ALL_WHERE_QUERY, queryPath: 'users', variables},
//    ];
// };

export const USER_SEARCH_ALL = {
   query: gql`
      query userSearch($type: UserSearchTypeValue) {
         userSearch: userSearch_All(type: $type) {
            ...userSearchInfo
         }
      }
      ${USER_SEARCH_FRAGMENT}
   `,
   typeKey: 'userSearch.type',
   queryPath: 'userSearch',
};

export const USER_SEARCH_DELETE = {
   mutation: gql`
      mutation userSearchDelete($id: UUID!) {
         userSearch_Delete(userSearchId: $id)
      }
   `,
   typeKey: 'userSearch.type',
   path: 'userSearch_Delete',
   actionKey: DELETE_ACTION,
   updateQueries: (variables) => [{...USER_SEARCH_ALL, variables}],
};

// export const USER_SEARCH_CREATE = {
//    mutation: gql`
//       mutation userSearchCreate(
//          $type: UserSearchTypeValue!
//          $jurisdictionIdList: [UUID]!
//          $localJurisdictionIdList: [UUID]!
//          $codeTypeIdList: [UUID]!
//       ) {
//          userSearch: userSearch_Create(
//             userSearch: {
//                type: $type
//                jurisdictionIdList: $jurisdictionIdList
//                localJurisdictionIdList: $localJurisdictionIdList
//                codeTypeIdList: $codeTypeIdList
//             }
//          ) {
//             ...userSearchInfo
//          }
//       }
//       ${USER_SEARCH_FRAGMENT}
//    `,
//    typeKey: 'userSearch.type',
//    resultType: 'UserSearch',
//    path: 'userSearch',
//    actionKey: CREATE_UPDATE_ACTION,
//    updateQueries: () => [{...USER_SEARCH_ALL}],
// };

export const USER_FAVOURITE_ALL = {
   query: gql`
      query userFavourite {
         userFavourite: userFavourite_All {
            ...userFavouriteInfo
         }
      }
      ${USER_FAVOURITE_FRAGMENT}
   `,
   typeKey: 'userFavourite.type',
   queryPath: 'userFavourite',
};

export const USER_FAVOURITE_ALL_WHERE = {
   query: gql`
      query userFavouriteAllWhere($resourceType: UserFavouriteTypeValue) {
         userFavourite: userFavourite_AllWhere(resourceType: $resourceType) {
            ...userFavouriteInfo
         }
      }
      ${USER_FAVOURITE_FRAGMENT}
   `,
   typeKey: 'userFavourite.type',
   queryPath: 'userFavourite',
};

export const USER_FAVOURITE_CREATE = {
   mutation: gql`
      mutation userFavouriteCreate($resourceType: UserFavouriteTypeValue!, $resourceId: UUID!) {
         userFavourite: userFavourite_Create(userFavourite: {resourceType: $resourceType, resourceId: $resourceId}) {
            ...userFavouriteInfo
         }
      }
      ${USER_FAVOURITE_FRAGMENT}
   `,
   typeKey: 'userFavourite.type',
   resultType: 'UserFavourite',
   path: 'userFavourite',
   actionKey: CREATE_UPDATE_ACTION,
   updateQueries: (variables) => [{...USER_FAVOURITE_ALL}, {...USER_FAVOURITE_ALL_WHERE, variables}],
};

export const USER_FAVOURITE_DELETE = {
   mutation: gql`
      mutation userFavouriteDelete($id: UUID!) {
         userFavourite: userFavourite_Delete(userFavouriteId: $id)
      }
   `,
   typeKey: 'userFavourite.type',
   path: 'userFavourite',
   actionKey: DELETE_ACTION,
   updateQueries: (variables) => [{...USER_FAVOURITE_ALL}, {...USER_FAVOURITE_ALL_WHERE, variables}],
};

export const CITY_ALL_QUERY = {
   query: gql`
      query getCityAll {
         cities: city_All {
            ...CityInfo
         }
      }
      ${CITY_FRAGMENT}
   `,
   path: 'cities',
   typeKey: 'city.type',
};

export const CITY_BY_ID_QUERY = {
   query: gql`
      query getCityById($cityId: UUID!) {
         city: city_ById(cityId: $cityId) {
            ...CityInfo
         }
      }
      ${CITY_FRAGMENT}
   `,
   typeKey: 'city.type',
   path: 'city',
};

export const CITY_CREATE_UPDATE = {
   mutation: gql`
      mutation cityCreateUpdate($id: UUID!, $name: String) {
         city: city_CreateUpdate(city: {id: $id, name: $name}) {
            ...CityInfo
         }
      }
      ${CITY_FRAGMENT}
   `,
   typeKey: 'city.type',
   path: 'city',
   updateQueries: () => [{...CITY_ALL_QUERY}],
};

export const CITY_DELETE = {
   mutation: gql`
      mutation cityDelete($id: UUID!) {
         city_Delete(cityId: $id)
      }
   `,
   typeKey: 'city.type',
   actionKey: DELETE_ACTION,
   updateQueries: () => [{...CITY_ALL_QUERY}],
};

// export const getCityCacheQueries = () => {
//    return [{query: CITY_ALL_QUERY, queryPath: 'cities'}];
// };

export const REPORT_TEMPLATE_CREATE_UPDATE = {
   mutation: gql`
      mutation ReportTemplate_CreateUpdate(
         $id: UUID!
         $name: String
         $subject: String
         $fromName: String
         $fromEmail: String
         $toEmail: String
         $type: ReportTemplateType!
         $scheduledDate: Timestamp!
         $autoSchedule: ReportTemplateAutoScheduleValue
         $startDate: DateOnly!
         $html: String!
         $hour: Int!
         $minute: Int!
         $days: [WeekDays]
         $customInterval: Int
         $customIntervalType: CustomIntervalTypeValue
      ) {
         reportTemplate: reportTemplate_CreateUpdate(
            reportTemplate: {
               id: $id
               name: $name
               subject: $subject
               fromName: $fromName
               fromEmail: $fromEmail
               toEmail: $toEmail
               type: $type
               scheduledDate: $scheduledDate
               autoSchedule: $autoSchedule
               startDate: $startDate
               html: $html
               hour: $hour
               minute: $minute
               days: $days
               customInterval: $customInterval
               customIntervalType: $customIntervalType
            }
         ) {
            ...reportTemplateInfo
         }
      }
      ${REPORT_TEMPLATE_FRAGMENT}
   `,
   typeKey: 'reportTemplate.type',
   actionKey: CREATE_UPDATE_ACTION,
   resultType: 'ReportTemplate',
   path: 'reportTemplate',
   updateQueries: (variables) => [{...REPORT_TEMPLATE_ALL_WHERE, variables}],
};

export const REPORT_TEMPLATE_BY_ID = {
   query: gql`
      query reportTemplateById($id: UUID!) {
         reportTemplate: reportTemplate_ById(id: $id) {
            ...reportTemplateInfo
         }
      }
      ${REPORT_TEMPLATE_FRAGMENT}
   `,
   typeKey: 'reportTemplate.type',
   path: 'reportTemplate',
};

export const REPORT_TEMPLATE_ALL_WHERE = {
   query: gql`
      query reportTemplateAllWhere($offset: Int, $limit: Int) {
         reportTemplates: reportTemplate_AllWhere(offset: $offset, limit: $limit) {
            ...reportTemplateInfo
         }
      }
      ${REPORT_TEMPLATE_FRAGMENT}
   `,
   typeKey: 'reportTemplate.type',
   path: 'reportTemplates',
};

export const REPORT_HISTORY_TO_FROM = {
   query: gql`
      query reportHistoryToFrom($dateFrom: Timestamp!, $dateTo: Timestamp!) {
         reportHistories: reportHistory_ToFrom(dateFrom: $dateFrom, dateTo: $dateTo) {
            ...reportHistoryInfo
         }
      }
      ${REPORT_HISTORY_FRAGMENT}
   `,
   typeKey: 'reportHistory.type',
   path: 'reportHistories',
};

export const REPORT_TEMPLATE_DELETE = {
   mutation: gql`
      mutation reportTemplateDelete($id: UUID!) {
         reportTemplate_Delete(reportTemplateId: $id)
      }
   `,
   typeKey: 'reportTemplate.type',
   path: 'reportTemplate_Delete',
   actionKey: DELETE_ACTION,
   updateQueries: (variables) => [{...REPORT_TEMPLATE_ALL_WHERE, variables}],
};

export const STATE_ALL_QUERY = {
   query: gql`
      query getStateAll {
         states: state_All {
            ...StateInfo
         }
      }
      ${STATE_FRAGMENT}
   `,
   typeKey: 'state.type',
};

export const STATE_BY_ID_QUERY = {
   query: gql`
      query getStateById($stateId: UUID!) {
         state: state_ById(stateId: $stateId) {
            ...StateInfo
         }
      }
      ${STATE_FRAGMENT}
   `,
   typeKey: 'state.type',
   path: 'state',
};

export const STATUS_ALL_QUERY = {
   query: gql`
      query getStatusAll {
         status: status_All {
            id
            label: name
         }
      }
   `,
   typeKey: 'status.type',
   path: 'status',
};

export const CONTACT_ALL_WHERE_QUERY = {
   query: gql`
      query getContacts($authorityId: [UUID]) {
         contact: contact_AllWhere(contactSearch: {authorityId: $authorityId}) {
            ...contactInfo
         }
      }
      ${CONTACT_FRAGMENT}
   `,
   typeKey: 'contact.type',
   path: 'contact',
};

export const CONTACT_BY_ID_QUERY = {
   query: gql`
      query getContactById($contactId: UUID!) {
         contact: contact_ById(contactId: $contactId) {
            ...contactInfo
         }
      }
      ${CONTACT_FRAGMENT}
   `,
   typeKey: 'contact.type',
   path: 'contact',
};

export const CONTACT_CREATE_UPDATE = {
   mutation: gql`
      mutation contactCreateUpdate(
         $id: UUID!
         $name: String
         $email: String
         $phone: String
         $fax: String
         $authorityId: UUID
      ) {
         contact: contact_CreateUpdate(
            contact: {id: $id, name: $name, email: $email, phone: $phone, fax: $fax, authorityId: $authorityId}
         ) {
            ...contactInfo
         }
      }
      ${CONTACT_FRAGMENT}
   `,
   typeKey: 'contact.type',
   resultType: 'Contact',
   path: 'contact',
   actionKey: CREATE_UPDATE_ACTION,
   updateQueries: (variables) => [{...CONTACT_ALL_WHERE_QUERY, variables}],
};

export const CONTACT_DELETE = {
   mutation: gql`
      mutation contactDelete($id: UUID!) {
         contact_Delete(contactId: $id)
      }
   `,
   typeKey: 'contact.type',
   path: 'contact_Delete',
   actionKey: DELETE_ACTION,
   updateQueries: (variables) => [{...CONTACT_ALL_WHERE_QUERY, variables}],
};

// export const getContactCacheQueries = () => {
//    return [CONTACT_ALL_WHERE_QUERY];
// };

// Search

export const CODE_SEARCH_WEEKLY_REPORT = {
   query: gql`
      query codeSearchWeeklyReport($startDate: DateOnly, $endDate: DateOnly) {
         weeklyReport: codeSearch_WeeklyReport(codeSearch: {reportStartDate: $startDate, reportEndDate: $endDate}) {
            ...codeInformationInfo
            ...codeInformationSearchInfo
         }
      }
      ${CODE_INFORMATION_FRAGMENT}
      ${CODE_INFORMATION_SEARCH_FRAGMENT}
   `,
   typeKey: 'code.type',
   path: 'weeklyReport',
};

// Search
export const CODE_SEARCH_CODE_INFORMATION = {
   query: gql`
      query codeSearchCodeInformation(
         $jurisdictionId: [UUID]
         $localJurisdictionId: [UUID]
         $codeTypeId: [UUID]
         $codeTitleId: [UUID]
         $codeEditionId: [UUID]
         $historicalDate: DateOnly
         $includeUpcomingMods: Boolean
         $modificationText: String
      ) {
         codeInformation: codeSearch_CodeInformation(
            includeUpcomingMods: $includeUpcomingMods
            codeSearch: {
               jurisdictionId: $jurisdictionId
               localJurisdictionId: $localJurisdictionId
               codeTypeId: $codeTypeId
               codeTitleId: $codeTitleId
               codeEditionId: $codeEditionId
               historicalDate: $historicalDate
               modificationText: $modificationText
            }
         ) {
            ...codeInformationInfo
            ...codeInformationSearchInfo
         }
      }
      ${CODE_INFORMATION_FRAGMENT}
      ${CODE_INFORMATION_SEARCH_FRAGMENT}
   `,
   typeKey: 'codeInformation.type',
   path: 'codeInformation',
};

export const CODE_SEARCH_PROGRAM_INFORMATION = {
   query: gql`
      query programInformationSearch($jurisdictionId: [UUID], $localJurisdictionId: [UUID], $codeTypeId: [UUID]) {
         authority: codeSearch_AuthorityInformation(
            codeSearch: {
               jurisdictionId: $jurisdictionId
               localJurisdictionId: $localJurisdictionId
               codeTypeId: $codeTypeId
            }
         ) {
            ...authoritySearchInfo
         }
      }
      ${AUTHORITY_SEARCH_FRAGMENT}
   `,
   typeKey: 'authority.type',
   path: 'authority',
};

export const USER_ROLES_ALL_QUERY = {
   query: gql`
      query roles {
         roles: role_All {
            id
            name
         }
      }
   `,
   typeKey: 'role.type',
   path: 'roles',
};

export const ROLE_ALL_WHERE_QUERY = {
   query: gql`
      query getRoleAllWhere($id: [UUID], $name: [String]) {
         roles: role_AllWhere(roleSearch: {id: $id, name: $name}) {
            id
            name
         }
      }
   `,
   typeKey: 'role.type',
   path: 'roles',
};

export const CODE_MODIFICATION_PENDING_QUERY = {
   query: gql`
      query codeModification_Pending($dateFrom: DateOnly) {
         modifications: codeModification_Pending(dateFrom: $dateFrom) {
            ...codeModificationPathInfo
         }
      }
      ${CODE_MODIFICATION_PATH_FRAGMENT}
   `,
   typeKey: 'codeModification.type',
   path: 'modifications',
};

export const SEND_FEEDBACK = {
   mutation: gql`
      mutation sendFeedback($sendCopy: Boolean, $email: String, $html: String!, $subject: String) {
         sendFeedback(email: $email, html: $html, subject: $subject, sendCopy: $sendCopy)
      }
   `,
};

import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import {USER_CREATE_UPDATE, USER_BY_COGNITO_SUB_QUERY} from '../data/QueriesGL';
import ModalDialog from '../fhg/components/dialog/ModalDialog';
import {userState} from '../fhg/components/security/AuthenticatedUser';
import useMutationFHG from '../fhg/hooks/data/useMutationFHG';
import useQueryFHG from '../fhg/hooks/data/useQueryFHG';

/**
 * Eula component. Shows terms & conditions if the user hasn't accepted them yet. User must not be an admin.
 * @return {React.JSX.Element|null}
 * @constructor
 */
export default function Eula() {
   const userStateData = useRecoilValue(userState);
   const navigate = useNavigate();

   const [userData] = useQueryFHG(
      USER_BY_COGNITO_SUB_QUERY,
      {variables: {cognitoSub: userStateData?.sub}, skip: !userStateData?.sub},
      'user.type'
   );

   const [userCreateUpdate] = useMutationFHG(USER_CREATE_UPDATE, {errorPolicy: 'all'});
   const [open, setOpen] = useState(true);

   /**
    * User rejected EULA, so log them out.
    */
   function handleReject() {
      (async () => {
         await userStateData.signOut();
         navigate('/');
         setOpen(true);
      })();
   }

   /**
    * User has accepted the EULA, so close the dialog and save the new state.
    */
   function handleAccept() {
      (async () => {
         try {
            const variables = {id: userData?.users?.[0]?.id, eulaConfirmed: true};
            const result = await userCreateUpdate({variables});

            if (!result.errors) {
               setOpen(false);
            }
         } catch (e) {
            console.log(e);
         }
      })();
   }

   if (!userStateData.isAdmin && userData?.users?.[0]?.id && !userData?.users?.[0]?.eulaConfirmed) {
      return (
         <ModalDialog
            open={open}
            titleKey={'eula.title'}
            messageKey={'eula.message'}
            submitKey={'eula.accept.button'}
            onSubmit={handleAccept}
            cancelKey={'eula.reject.button'}
            onClose={handleReject}
            maxWidth={'sm'}
            fullWidth={true}
            typographyProps={{hasLineBreaks: true, hasBold: true}}
         />
      );
   } else {
      return null;
   }
}

import React, {lazy} from 'react';
import {Navigate, Outlet, Routes, Route} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import SettingOutlet from '../components/SettingOutlet';
import {
   SETTINGS_PATH,
   PROGRAM_PATH,
   AUTHORITY_WEB_SITES_PATH,
   CLIENT_USER_PATH,
   CLIENT_PATH,
   SEARCH_PATH,
   AUTHORITY_CONTACTS_PATH,
   AUTHORITY_ADDRESS_PATH,
   AUTHORITY_NAME_PATH,
   USER_ITEM_PATH,
   USER_PATH,
   EDIT_PATH,
   CODE_PATH,
   AUTHORITY_PATH,
   ADMIN_PATH,
   WEEKLY_REPORT_TEMPLATES_PATH,
   WEEKLY_REPORT_TEMPLATE_ITEM_PATH,
   WEEKLY_REPORTS_PATH,
   WEEKLY_REPORT_REPORT_ITEM_PATH,
   WEEKLY_PATH,
   CODE_EDIT_FULL_PATH,
   CONTACT_US_PATH,
} from '../Constants';
import {userState} from '../fhg/components/security/AuthenticatedUser';
import SuspenseLx from '../fhg/components/SuspenseLx';

const ErrorStateSnackbar = lazy(() => import('../fhg/components/ErrorStateSnackbar'));
const Authority = lazy(() => import('./admin/Authority'));
const Code = lazy(() => import('./admin/Code'));
const Search = lazy(() => import('./admin/Search/Search'));
// Authority
const NameEdit = lazy(() => import('./admin/authority/NameEdit'));
const AddressEdit = lazy(() => import('./admin/authority/AddressEdit'));
const ContactEdit = lazy(() => import('./admin/authority/ContactEdit'));
const WebsitesEdit = lazy(() => import('./admin/authority/WebsiteEdit'));
const CodeModificationEdit = lazy(() => import('./admin/CodeModificationEdit'));
const ClientDashboard = lazy(() => import('./ClientDashboard'));
const Dashboard = lazy(() => import('./Dashboard'));
const UserEdit = lazy(() => import('./admin/UserEdit'));
const ClientUserEdit = lazy(() => import('./admin/ClientUserEdit'));
const CodeInformationResult = lazy(() => import('./admin/Search/CodeInformationResult'));
const ProgramInformation = lazy(() => import('./admin/Search/ProgramInformation'));
const WeeklyReportResult = lazy(() => import('./admin/Search/WeeklyReportResult'));
const ContactUs = lazy(() => import('./admin/Search/ContactUs'));

// admin weekly report
const WeeklyReportTemplatesList = lazy(() => import('./admin/WeeklyReport/Templates/TemplatesList'));
const WeeklyReportTemplateEdit = lazy(() => import('./admin/WeeklyReport/Templates/TemplateEdit'));

const WeeklyReportReportsList = lazy(() => import('./admin/WeeklyReport/Reports/ReportsList'));
const WeeklyReportReportEdit = lazy(() => import('./admin/WeeklyReport/Reports/ReportEdit'));

/**
 * Main component accessible only if the user has been authenticated. Contains the routing for the application.
 *
 * Reviewed:
 */
export default function Main() {
   const {isAdmin} = useRecoilValue(userState);

   if (isAdmin !== undefined) {
      return (
         <>
            <ErrorStateSnackbar />
            <Routes>
               <Route path={'/'} element={<Outlet />}>
                  <Route path={ADMIN_PATH} element={<Dashboard />}>
                     <Route path={AUTHORITY_PATH} element={<SuspenseLx e={<Authority />} />}>
                        <Route path={AUTHORITY_NAME_PATH} element={<SuspenseLx e={<NameEdit />} />} />
                        <Route path={AUTHORITY_ADDRESS_PATH} element={<SuspenseLx e={<AddressEdit />} />} />
                        <Route path={AUTHORITY_CONTACTS_PATH} element={<SuspenseLx e={<ContactEdit />} />} />
                        <Route path={AUTHORITY_WEB_SITES_PATH} element={<SuspenseLx e={<WebsitesEdit />} />} />
                     </Route>
                     <Route path={CODE_PATH} element={<SuspenseLx e={<Code />} />}>
                        <Route path={EDIT_PATH} element={<SuspenseLx e={<CodeModificationEdit />} />} />
                     </Route>
                     {/*Settings*/}
                     <Route path={SETTINGS_PATH} element={<SettingOutlet titleKey={'searchSidebar.settings.label'} />}>
                        <Route path={USER_PATH} element={<Outlet />}>
                           <Route path={`:${USER_ITEM_PATH}`} element={<SuspenseLx e={<UserEdit />} />} />
                        </Route>
                        <Route path={CLIENT_USER_PATH} element={<Outlet />}>
                           <Route path={`:${USER_ITEM_PATH}`} element={<SuspenseLx e={<ClientUserEdit />} />} />
                        </Route>
                        <Route
                           path={WEEKLY_REPORT_TEMPLATES_PATH}
                           element={<SuspenseLx e={<WeeklyReportTemplatesList />} />}
                        />
                        <Route
                           path={`${WEEKLY_REPORT_TEMPLATES_PATH}/:${WEEKLY_REPORT_TEMPLATE_ITEM_PATH}`}
                           element={<SuspenseLx e={<WeeklyReportTemplateEdit />} />}
                        />
                        <Route path={WEEKLY_REPORTS_PATH} element={<SuspenseLx e={<WeeklyReportReportsList />} />} />
                        <Route
                           path={`${WEEKLY_REPORTS_PATH}/:${WEEKLY_REPORT_REPORT_ITEM_PATH}`}
                           element={<SuspenseLx e={<WeeklyReportReportEdit />} />}
                        />
                     </Route>
                     {/*end Settings*/}
                  </Route>
                  <Route path={CLIENT_PATH} element={<SuspenseLx e={<ClientDashboard />} />}>
                     <Route path={SEARCH_PATH} element={<SuspenseLx e={<Search />} />}>
                        <Route path={CODE_PATH} element={<SuspenseLx e={<CodeInformationResult />} />}></Route>
                        <Route path={PROGRAM_PATH} element={<SuspenseLx e={<ProgramInformation />} />}></Route>
                        <Route path={WEEKLY_PATH} element={<SuspenseLx e={<WeeklyReportResult />} />}></Route>
                        <Route path={CONTACT_US_PATH} element={<SuspenseLx e={<ContactUs />} />}></Route>
                     </Route>
                  </Route>
                  <Route
                     index
                     element={
                        <Navigate replace to={isAdmin ? CODE_EDIT_FULL_PATH : `/${CLIENT_PATH}/${SEARCH_PATH}`} />
                     }
                  />
               </Route>
            </Routes>
         </>
      );
   } else {
      return null;
   }
}

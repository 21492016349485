import {Auth} from '@aws-amplify/auth';
import {StyledEngineProvider} from '@mui/material';
import {lazy} from 'react';
import React from 'react';
import './index.css';
import App from './App';
import {LOGO} from './Constants';
import Loading from './fhg/components/Loading';
import SuspenseLx from './fhg/components/SuspenseLx';
import reportWebVitals from './reportWebVitals';
import {RetryLink} from '@apollo/client/link/retry';
import {setContext} from '@apollo/client/link/context';
import {HttpLink, ApolloClient, InMemoryCache, ApolloLink, ApolloProvider} from '@apollo/client';
import {ENDPOINT} from './Constants';
import QueueLink from 'apollo-link-queue';
import {createRoot} from 'react-dom/client';
// import {offsetLimitPagination} from '@apollo/client/utilities';
const RecoilRoot = lazy(() => import('./packageExports/RecoilRoot'));
const BrowserRouter = lazy(() => import('./packageExports/BrowserRouter'));
const ThemeProvider = lazy(() => import('./components/ThemeProvider'));

const getAccessToken = () => {
   return Auth.currentSession()
      .then((session) => {
         return session.accessToken.jwtToken;
      })
      .catch((error) => {
         console.log(error);
         throw error;
      });
};

const authLink = setContext(async (_, {headers}) => {
   let token = await getAccessToken();

   return {
      headers: {
         ...headers,
         accesstoken: token || '',
      },
   };
});

const queueLink = new QueueLink();
window.addEventListener('offline', () => queueLink.close());
window.addEventListener('online', () => queueLink.open());

const client = new ApolloClient({
   link: ApolloLink.from([new RetryLink(), queueLink, authLink, new HttpLink({uri: ENDPOINT})]),
   cache: new InMemoryCache(),
   // cache: new InMemoryCache({
   //    typePolicies: {
   //       Query: {
   //          fields: {
   //             jurisdictions: {
   //                ...offsetLimitPagination(['first', 'skip']),
   //                //Cache holds the entire list of Return from cache the .
   //                read(existing, test, test2) {
   //                   debugger;
   //                   return existing;
   //                },
   //             },
   //          },
   //       },
   //    },
   // }),
});

// Add the format command for adding parameters to strings. For Example:
//    'This is a test: {testName}'.format({testName: 'Test Hello World'})
if (!String.prototype.format) {
   // eslint-disable-next-line
   String.prototype.format = function (values) {
      return this.replace(/{(\w+)}/g, function (match, key) {
         return typeof values[key] !== 'undefined' ? values[key] : match;
      });
   };
}

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

let img = new Image();
img.src = LOGO;

root.render(
   <React.StrictMode>
      <SuspenseLx fallback={<Loading isLoading />}>
         <RecoilRoot>
            <ApolloProvider client={client}>
               <BrowserRouter>
                  <StyledEngineProvider injectFirst>
                     <ThemeProvider>
                        <App />
                     </ThemeProvider>
                  </StyledEngineProvider>
               </BrowserRouter>
            </ApolloProvider>
         </RecoilRoot>
      </SuspenseLx>
   </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

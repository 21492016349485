import {Heading, Image, Text, Authenticator, Flex, Link, useAuthenticator, useTheme} from '@aws-amplify/ui-react';
import {useMediaQuery} from '@mui/material';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import {I18n} from 'aws-amplify';
import {LOGO_HORIZ_LIGHT, LOGO_LARGE} from '../../../Constants';
import AuthenticatedUser from './AuthenticatedUser';
import './Authenticator.css';

I18n.putVocabulariesForLanguage('en', {
   Username: 'Enter your username', // Username label
   Password: 'Enter your password', // Password label
});

function Header() {
   const {tokens} = useTheme();

   return (
      <Image
         alt='logo'
         src={LOGO_HORIZ_LIGHT}
         width={300}
         padding={tokens.space.medium}
         style={{alignSelf: 'center'}}
      />
   );
}
function Footer() {
   const {tokens} = useTheme();

   return (
      <Flex justifyContent='center' padding={tokens.space.medium}>
         <Text>&copy; All Rights Reserved</Text>
      </Flex>
   );
}
function SignInHeader() {
   const {tokens} = useTheme();

   return (
      <Heading level={3} padding={`${tokens.space.xl} ${tokens.space.xl} 0`}>
         Sign in to your Account
      </Heading>
   );
}

function SignInFooter() {
   const {toResetPassword} = useAuthenticator();
   const {tokens} = useTheme();

   return (
      <Flex justifyContent='center' padding={`0 0 ${tokens.space.medium}`}>
         <Link onClick={toResetPassword}>Reset your password</Link>
      </Flex>
   );
}
const testComponents = {
   Header,
   SignIn: {
      Header: SignInHeader,
      Footer: SignInFooter,
   },
   Footer,
};

/**
 * Authenticator for the app. The children won't be displayed until the user has logged in.
 *
 * @param theme The theme for the AWS authentication.
 * @param components The components for the AWS authentication.
 * @param children
 * @return {JSX.Element}
 * @constructor
 */
export default function AuthenticatorInner({components = testComponents, children}) {
   const isSmall = useMediaQuery('(max-width:1056px)');
   const {authStatus} = useAuthenticator();

   return (
      <Stack
         direction={'row'}
         display={'flex'}
         sx={{bgcolor: '#08213F'}}
         height={'100%'}
         width={'100%'}
         overflow='hidden'
      >
         {authStatus === 'unauthenticated' && !isSmall && (
            <>
               <Stack flex={'1 1'} height={'100%'}>
                  <img alt='logo' src={LOGO_LARGE} width={'100%'} />
               </Stack>
               <Divider />
            </>
         )}
         <Stack flex={'1 1'} height={'100%'} width={'100%'} overflow='hidden' sx={{bgcolor: 'white'}}>
            <Authenticator components={components}>
               {(authState) => <AuthenticatedUser authState={authState}>{children}</AuthenticatedUser>}
            </Authenticator>
         </Stack>
      </Stack>
   );
}

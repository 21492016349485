import Stack from '@mui/material/Stack';
import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

const ScrollStack = React.forwardRef(function ScrollStack({innerStackProps, onScroll, children, ...props}, ref) {
   const isPrinting = useMediaQuery('print');

   return (
      <Stack name='Scroll Stack' overflow={'hidden'} {...props} style={{scrollBehavior: 'smooth'}}>
         <Stack
            ref={ref}
            name='Scroll Stack Inner'
            {...innerStackProps}
            overflow={isPrinting ? 'hidden' : 'auto'}
            height={isPrinting ? '100%' : undefined}
            onScroll={onScroll}
         >
            {children}
         </Stack>
      </Stack>
   );
});

export default ScrollStack;

import {Stack} from '@mui/system';
import React from 'react';
import {useOutlet} from 'react-router-dom';
import TypographyFHG from '../fhg/components/Typography';
import usePageTitle from '../fhg/hooks/usePageTitle';

/**
 * An outlet component to show the settings page title if no child outlet.
 * @return {JSX.Element|null} Outlet .
 * @constructor
 */
export default function SettingOutlet({title, titleKey, values}) {
   usePageTitle({title, titleKey, values});
   const outlet = useOutlet();

   return (
      <Stack flexDirection={'row'} height={'100%'} overflow={'hidden'}>
         {outlet ? (
            outlet
         ) : (
            <Stack flexDirection={'column'}>
               <TypographyFHG sx={{ml: 14, mt: 3}} variant={'h4'} id={'searchSidebar.settings.label'} />
               <TypographyFHG sx={{ml: 14, mt: 3}} variant={'subtitle1'} id={'setting.setting.message'} />
            </Stack>
         )}
      </Stack>
   );
}

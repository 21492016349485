// Dates displayed to the user or to match the DB format.
export const DATE_FORMAT_KEYBOARD = 'MM/DD/YYYY';
export const MONTH_FORMAT = 'MM/YYYY';
export const MONTH_FORMAT1 = 'M/YYYY';
export const MONTH_FORMAT3 = 'MM/YY';
export const MONTH_FORMAT4 = 'M/YY';
// export const MONTH_ONLY_FORMAT = 'MMM';
// export const YEAR_FORMAT = 'YYYY';
export const DATE_TIME_FORMAT = 'M/D/YYYY hh:mm a';
export const DATE_DB_FORMAT = 'YYYY-MM-DD';
// export const CURRENCY_FORMAT = '$#,###,###,##0.';
// export const CURRENCY_FULL_FORMAT = '$#,###,###,##0.00';
// export const POSITION_DATE = 'MMM-YYYY';

export const EMPTY_OBJECT = {};
export const ERROR_COLOR = '#AA0B06';
export const WARNING_COLOR = '#F5CD19';
export const SUCCESS_COLOR = '#5C9E52';

export const colors = [
   '#EDBA2E',
   // '#4386C3',
   '#A3C7E4',
   '#2170B8',
   '#DC2A30CB',
   '#6A8E9A',
   '#008080',
   '#B521B8',
   // '#FFCA42',
   '#CFB047',
   '#939393',
   // '#3B8AD2',
   '#038B94',
];

// export const COLLAPSE_TIMEOUT = 500;
export const UNDO_DURATION = 4000;

export const ENDPOINT = !process.env.REACT_APP_ENDPOINT
   ? '/api/graphql/'
   : `http://${process.env.REACT_APP_ENDPOINT}/api/graphql/`;

export const FILE_BUCKET = process.env.REACT_APP_POOL === 'production' ? 'neii-prod-files' : 'neii-test-files';

console.log('Endpoint = ', ENDPOINT);
console.log('Version = ', process.env.REACT_APP_VERSION);

export const APPBAR_HEIGHT = 0;
export const APPBAR_SMALL_HEIGHT = 0;
export const DRAWER_WIDTH = 320;
// export const EDIT_DRAWER_WIDTH = 300;
export const ADMIN_DRAWER = DRAWER_WIDTH;
export const CLIENT_DRAWER = DRAWER_WIDTH;
export const LOGO_BOX_HEIGHT = 123;
// export const LIST_WIDTH_DEFAULT = 360;
export const ITEM_SIZE = 38;
//For the Grid Column Width in the search
export const GRID_COLUMN_WIDTH = 240;
export const RESPONSIVE_SMALL = 630;
// LEVELS for Modifications
export const LEVEL_TO_COLLAPSE = 3;
export const MAX_MODIFICATIONS_TO_COLLAPSE = 2;

///////
export const DEFAULT_COLLAPSED_SIZE = 2 * ITEM_SIZE + ITEM_SIZE / 2;

export const CODE_EDIT_DETAIL_WIDTH = 330;
export const CODE_EDIT_DETAIL_MIN_WIDTH = 260;
export const CODE_EDIT_SELECT_MAX_WIDTH = 400;

// TODO change to real address when we get it.
export const CONTACT_EMAIL = 'codefinder@NationalElevatorIndustry.org';
export const LOGO = '/images/NEII_Logo_Standards_small.png';
export const LOGO_SMALL = LOGO;
export const LOGO_SMALL_WHITE = '/images/NEII_Logo_1.jpeg';
export const LOGO_HORIZ_LIGHT = '/images/NEII_Logo_1.jpeg';
export const LOGO_LARGE = '/images/NEII_Logo_Standards.png';
export const EDIT_PENCIL_ICON = '/images/Edit.svg';
export const DELETE_ICON = '/images/Delete.svg';
// export const CALENDAR_ICON = '/images/Calendar.svg';
export const ADD_ICON = '/images/Add.svg';
// export const FILTER_LIST_ICON = '/images/Filter1.svg';
// export const FILTER_SIDE_ICON = '/images/Filter2.svg';
export const CONTENT_ACTIVE_ICON = '/images/Content-Active.svg';
export const CONTENT_ICON = '/images/Content.svg';
export const CONTENT_TWO_COLUMN_ACTIVE_ICON = '/images/Two-Column-Active.svg';
export const CONTENT_TWO_COLUMN_ICON = '/images/Two-Column.svg';

// Types category
// export const CODE_TYPE_TYPE = 'code';

// The default page size for lists.
export const DEFAULT_PAGE_SIZE = 33;

export const JURISDICTION = 'jurisdiction';
export const LOCAL_JURISDICTION = 'localJurisdiction';
export const AUTHORITY = 'authority';
export const CODE_TYPE = 'codeType';
export const CODE_TITLE = 'codeTitle';
export const CODE_EDITION = 'codeEdition';
export const EFFECTIVE_DATE = 'effectiveDate';
export const CODE_INFORMATION = 'codeInformation';
export const DATE_PARAM = 'date';

// Routes
export const DEFAULT_PATH = '/';
export const NEW_PATH = 'new';
// export const QUOTE_PATH = 'quote';
export const ADMIN_PATH = 'admin';
export const CLIENT_PATH = 'client';
export const EDIT_PATH = 'edit';
export const EDIT_FULL_PATH = `${ADMIN_PATH}`;
export const EDIT_FULL_PATH_TYPE = `${ADMIN_PATH}/:type`;
export const USER_PATH = 'user';
export const CLIENT_USER_PATH = 'client-user';
export const USER_ITEM_PATH = 'userId';
export const SETTINGS_PATH = 'settings';
export const USER_FULL_PATH = `${ADMIN_PATH}/${SETTINGS_PATH}/${USER_PATH}`;
export const SETTINGS_FULL_PATH = `${ADMIN_PATH}/${SETTINGS_PATH}`;
export const WEEKLY_REPORT_TEMPLATES_PATH = 'templates';
export const WEEKLY_REPORT_TEMPLATES_FULL_PATH = `/${ADMIN_PATH}/${SETTINGS_PATH}/${WEEKLY_REPORT_TEMPLATES_PATH}`;
export const WEEKLY_REPORT_TEMPLATE_ITEM_PATH = 'templateId';
export const WEEKLY_REPORTS_PATH = 'reports';
export const WEEKLY_REPORT_REPORT_ITEM_PATH = 'reportId';
export const WEEKLY_REPORT_REPORT_FULL_PATH = `/${ADMIN_PATH}/${SETTINGS_PATH}/${WEEKLY_REPORTS_PATH}`;
export const AUTHORITY_PATH = 'authority';
export const AUTHORITY_NAME_PATH = 'name';
export const AUTHORITY_ADDRESS_PATH = 'address';
export const AUTHORITY_CONTACTS_PATH = 'contact/:contactId';
export const AUTHORITY_WEB_SITES_PATH = 'webSite/:webSiteId';
export const CODE_PATH = 'code';
export const PROGRAM_PATH = 'program';
export const WEEKLY_PATH = 'weekly';
export const CONTACT_US_PATH = 'contactUs';
export const SEARCH_PATH = 'search';
// export const AUTHORITY_FULL_PATH = `${ADMIN_PATH}/${AUTHORITY_PATH}`;
// export const SEARCH_FULL_PATH = `${ADMIN_PATH}/${SEARCH_PATH}`;

export const SEARCH_FULL_PATH2 = `${CLIENT_PATH}/${SEARCH_PATH}`;

export const TEST_SEARCH_FULL_PATH = `${CLIENT_PATH}/${SEARCH_PATH}/:category`;
// export const CODE_FULL_PATH = `${ADMIN_PATH}/${CODE_PATH}`;
export const CODE_EDIT_FULL_PATH = `/${EDIT_FULL_PATH}/${CODE_PATH}`;
export const AUTHORITY_EDIT_FULL_PATH = `/${EDIT_FULL_PATH}/${AUTHORITY_PATH}`;
// export const CLIENT_FULL_PATH = `${ADMIN_PATH}/${CLIENT_PATH}`;
// export const SIGN_OUT_PATH = 'signout';

// export const REPORT_PATH = 'report';
// export const REPORT_TYPE_PATH = ':reportTypeId';

export const ADMIN_GROUP = 'Admin';

// TYPES
export const WEBSITE_TYPE = 'website';

export const LIST_STYLE_GRID = 'list_grid';
export const LIST_STYLE_LIST = 'list_list';

export const SEARCH_WEEKLY_REPORT = 'weekly';
export const SEARCH_CODE_INFORMATION = 'code';
export const SEARCH_PROGRAM_INFORMATION = 'program';

export const USER_ROLE_CLIENT = 'Client';
export const USER_ROLE_ADMIN = 'Admin';

export const TEMPLATE_WHEN_NOW = 'NOW';
export const TEMPLATE_WHEN_SCHEDULE = 'SCHEDULED';
export const TEMPLATE_WHEN_AUTOMATIC = 'AUTOMATIC';

export const TEMPLATE_AUTOMATIC_EVERYDAY = 'DAILY';
export const TEMPLATE_AUTOMATIC_EVERYWEEK = 'WEEKLY';
export const TEMPLATE_AUTOMATIC_ON_THE = 'ON_THE';
export const TEMPLATE_AUTOMATIC_EVERY = 'CUSTOM';
export const TEMPLATE_AUTOMATIC_EVERY_HOURS = 'HOURS';
export const TEMPLATE_AUTOMATIC_EVERY_DAYS = 'DAYS';
export const TEMPLATE_AUTOMATIC_EVERY_WEEKS = 'WEEKS';
export const TEMPLATE_AUTOMATIC_EVERY_MONTHS = 'MONTHS';

export const FAVOURITE_RESOURCE_TYPE_JURISDICTION = 'JURISDICTION';
export const FAVOURITE_RESOURCE_TYPE_LOCAL_JURISDICTION = 'LOCAL_JURISDICTION';
export const FAVOURITE_RESOURCE_TYPE_CODE_TYPE = 'CODE_TYPE';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, FormattedNumber} from 'react-intl';
import {EMPTY_OBJECT} from '../../Constants';

/**
 * The Typography component that supports intl. The default value is the child element. The id is the lookup value for
 * the locale file.
 *
 * Example:
 * <Typography id='path.suppliers' variant='button'>Suppliers</Typography>
 *
 * Reviewed:
 */
const TypographyFHG = React.forwardRef(function TypographyFHG(
   {id, children, values = EMPTY_OBJECT, type = 'text', hasBold, intlProps, hasLineBreaks, ...otherProps},
   ref
) {
   if (hasBold || values.hasBold) {
      values.b = (...chunks) => <b>{chunks}</b>;
   }

   function linesToParagraphs(nodes) {
      return nodes
         .map((node) =>
            typeof node === 'string' ? node.split('\n').map((text, index) => <p key={index}>{text}</p>) : node
         )
         .reduce((nodes, node) => nodes.concat(node), []);
   }

   if (id) {
      if (type === 'number') {
         return (
            <Typography ref={ref} id={id} {...otherProps}>
               <FormattedNumber id={id} defaultMessage={children} values={values} {...intlProps} />
            </Typography>
         );
      }
      return (
         <Typography ref={ref} id={id} {...otherProps}>
            {children ? (
               <Stack alignItems={'center'} justify={'center'}>
                  <FormattedMessage id={id} defaultMessage={children} values={values} {...intlProps}>
                     {hasLineBreaks ? linesToParagraphs : undefined}
                  </FormattedMessage>
                  {children}
               </Stack>
            ) : (
               <FormattedMessage id={id} defaultMessage={children} values={values} {...intlProps}>
                  {hasLineBreaks ? linesToParagraphs : undefined}
               </FormattedMessage>
            )}
         </Typography>
      );
   } else {
      return (
         <Typography ref={ref} {...otherProps}>
            {children}
         </Typography>
      );
   }
});

// noinspection JSUnresolvedVariable
TypographyFHG.propTypes = {
   id: PropTypes.string, // Key to message in the localization file.
   values: PropTypes.object, // Values to use to fill parameters in the localized message.
   ...Typography.propTypes, // Supports all the properties from Typography.
};

TypographyFHG.defaultProps = {
   variant: 'inherit',
};
TypographyFHG.displayName = 'TypographyFHG';

export default TypographyFHG;

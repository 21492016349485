export const authenticatorTheme = {
   name: 'my-theme',
   tokens: {
      colors: {
         font: {
            primary: {value: '#08213F'},
            secondary: {value: '#C79403'},
            tertiary: {value: '#038b94'},
            interactive: {value: '#038b94'},
            hover: {value: '#36BEC7'},
         },
      },
      components: {
         button: {
            // this will affect the font weight of all button variants
            fontWeight: {value: '{fontWeights.black.value}'},
            // style the primary variation
            primary: {
               backgroundColor: {value: '#C79403'},
               _hover: {
                  backgroundColor: {value: '#ad8301'},
               },
            },
         },
      },
   },
};

import {isEqual, escapeRegExp, filter, isEmpty, isObject, join as _join} from 'lodash';
import castArrayLD from 'lodash/castArray';
import concatLD from 'lodash/concat';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import isObjectLike from 'lodash/isObjectLike';
import _toNumber from 'lodash/toNumber';
import {validate} from 'uuid';
import {CONTACT_EMAIL} from '../../Constants';

/**
 * Format the message for localization. The default message has the id appended in non-production versions.
 *
 * @param intl             // Intl for localization.
 * @param id               // Message ID from localization file.
 * @param {string} [defaultMessage]   // Default message to use if id cannot be found in localization file.
 * @param [values]           // Values to insert in the localized message.
 * @return {string}        // Localized message.
 */
export function formatMessage(intl, id, defaultMessage, values) {
   const newDefaultMessage = process.env.NODE_ENV === 'production' ? defaultMessage : `${defaultMessage} (${id})`;

   if (id) {
      return intl ? intl.formatMessage({id, defaultMessage: newDefaultMessage}, values) : newDefaultMessage;
   } else {
      return '';
   }
}

export function removeOne(array, index) {
   if (index >= 0 && array && array.length) {
      let len = array.length;
      if (!len) {
         return;
      }
      len -= 1;
      while (index < len) {
         array[index] = array[index + 1];
         index++;
      }
      array.length--;
   }
   return array;
}

/**
 * Determines if the item has a value (i.e. not undefined, not null, nor empty string).
 *
 * @param item The item to check.
 * @return {boolean} True if the item is not undefined, not null, and not the empty string.
 */
export function hasValue(item) {
   return (
      !isNil(item) &&
      item !== '' &&
      (!isArray(item) || item.length > 0) &&
      (!isObjectLike(item) || Object.keys(item).length > 0)
   );
}
// export async function fetchData(url = '', method = 'GET', data) {
//    const response = await fetch(url, {
//       method,
//       headers: {
//          // 'x-api-key': 'AIzaSyA35411U4UavTPdKVW-HTbY8HWvdny_QSA',
//          'Content-Type': 'application/json',
//       },
//       body: method !== 'GET' && data ? JSON.stringify(data) : undefined,
//    });
//    return response.json(); // parses JSON response into native JavaScript objects
// }

export const editChange = (
   event,
   value = undefined,
   reason = '',
   isComponent = true,
   newValue = undefined,
   name = undefined
) => {
   let nextValue;
   let componentName = name;

   if (newValue === undefined) {
      if (value && (reason === 'blur' || reason === 'create-option' || reason === 'selectOption')) {
         nextValue = typeof value === 'string' ? value : value.id;
         componentName =
            name ||
            get(event, 'target.parentElement.dataset.optionname') ||
            get(event, 'target.firstElementChild.dataset.optionname') ||
            event.target.name;
      } else if (value && reason === 'date-picker') {
         nextValue = value;
         componentName = event.target.name;
      } else {
         if (event && event.target) {
            switch (event.target.type) {
               case 'number':
                  nextValue = isNaN(event.target.valueAsNumber) ? null : event.target.valueAsNumber;
                  break;
               case 'checkbox':
                  nextValue = event.target.checked;
                  break;
               case 'date-range':
                  nextValue = event.target.date;
                  break;
               case 'react-select':
                  nextValue = event.target.value;
                  break;
               case 'react-number-format':
                  nextValue = event.target.valueAsNumber || toNumber(event.target.value);
                  break;

               default:
                  const type = get(event, 'target.dataset.type');
                  if (type === 'number') {
                     nextValue = event.target.valueAsNumber || toNumber(event.target.value);
                     if (isNaN(nextValue)) {
                        nextValue = '';
                     }
                  } else if (type === 'boolean') {
                     nextValue = event.target.value === 'true';
                  } else {
                     nextValue = event.target.value;
                  }
                  break;
            }
            componentName = event.target.name;
         } else {
            console.log('event.target is null');
         }
      }
   }

   if (newValue) {
      return isComponent ? newValue : {componentName, newValue: newValue[name]};
   } else if (isComponent) {
      return {[componentName]: nextValue};
   }
   return {componentName, newValue: nextValue};
};

export function toNumber(value, isAllowBlank = true, blankDefault = null) {
   if (value === null || value === undefined || value === 'null' || (isAllowBlank && value === '')) {
      return blankDefault;
   } else {
      return _toNumber(value);
   }
}

export function resultOf(item, argument, defaultItem) {
   let result = typeof item === 'function' ? item(argument) : item;

   return result || defaultItem || {};
}

// export function numberFromText(text) {
//    // numberFromText("AA");
//    const charCodes = text
//       .split(' ') // => ["A", "A"]
//       .map((char) => char.charCodeAt(0)); // => [65, 65]
//    return parseInt(sum(charCodes)+'', 10);
// }

/**
 *
 * @param [array=[]]
 * @param [idList=[]]
 * @param allowIdInResult If the item in the id list isn't found in the array put it in the results, if it isn't a valid UUId.
 * @return {[]}
 */
export function findByIdByValueOrder(array = [], idList = [], allowIdInResult = true) {
   const result = [];

   if (isArray(array) && isArray(idList)) {
      for (const id of idList) {
         const arrayElement = find(array, {id});
         if (!!arrayElement) {
            result.push(arrayElement);
         } else if (allowIdInResult && !validate(id)) {
            result.push(id);
         }
      }
   }
   return result;
}

// export function humanFileSize(bytes, si = false, dp = 1) {
//    const thresh = si ? 1000 : 1024;
//
//    if (Math.abs(bytes) < thresh) {
//       // if (bytes <= 1) {
//       //    return '-';
//       // }
//       return bytes + ' bytes';
//    }
//
//    const units = si
//       ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
//       : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
//    let u = -1;
//    const r = 10 ** dp;
//
//    do {
//       bytes /= thresh;
//       ++u;
//    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
//
//    return bytes.toFixed(dp) + ' ' + units[u];
// }

export function getExtension(name) {
   if (name) {
      const lastIndex = name.lastIndexOf('.');
      if (lastIndex >= 0) {
         return name.substring(lastIndex + 1);
      }
   }
   return '';
}

/**
 *
 * @param {Object} file
 * @param {string} file.fileFilename
 * @param {string} file.name
 * @return {string}
 */
export function getExtensionIcon(file) {
   const fileName = file?.name || file?.fileFilename;

   if (fileName) {
      const extension = getExtension(fileName);
      return 'fiv-sqo fiv-icon-' + extension;
   }
   console.log('The file is not valid', file);
}

// export function showFile(title, blob, type, isBlob = false) {
//    // It is necessary to create a new blob object with mime-type explicitly set
//    // otherwise only Chrome works like it should
//    try {
//       const newBlob = !isBlob ? new Blob(blob) : blob; // new Blob([blob], {type: "application/pdf"})
//
//       // IE doesn't allow using a blob object directly as link href
//       // instead it is necessary to use msSaveOrOpenBlob
//       // @ts-ignore
//       if (window.navigator && window.navigator.msSaveOrOpenBlob) {
//          // @ts-ignore
//          window.navigator.msSaveOrOpenBlob(newBlob, title + '.pdf');
//          return;
//       }
//
//       // For other browsers:
//       // Create a link pointing to the ObjectURL containing the blob.
//       const data = window.URL.createObjectURL(newBlob);
//       const link = document.createElement('a');
//       link.href = data;
//       link.target = '_blank';
//       if (!type || title.endsWith('.' + type)) {
//          link.download = title;
//       } else {
//          link.download = title + '.' + type;
//       }
//       document.body.appendChild(link);
//       link.click();
//
//       link.parentNode.removeChild(link);
//    } catch (error) {
//       console.log(error);
//    }
// }

/**
 * Creates a new unique filename from the selectedName in the directory.
 *
 * NOTICE: This function assumes the selectedName is NOT unique (i.e. a copy is being made). If the name might be
 * unique, call isUnique first before calling this function.
 *
 * @param selectedName The name to create a unique name from.
 * @param files The file list to determine uniqueness of the name.
 * @returns {string} The unique name in the directory.
 */
export const createUniqueName = (selectedName, files) => {
   const extensionIndex = selectedName.lastIndexOf('.');
   let justName = extensionIndex >= 0 ? selectedName.substr(0, extensionIndex) : selectedName;
   let extension = extensionIndex >= 0 ? selectedName.substr(extensionIndex) : '';
   let newName = justName + ' Copy';

   // Search for [newName] Copy ([number]) OR [newName] Copy.
   const copyNameMore = '^' + escapeRegExp(newName) + '(\\(\\d+\\))?$';
   const matchingFiles = filter(files, (file) => {
      const key = file.name;

      if (key?.length > 0) {
         const extensionIndex2 = key.lastIndexOf('.');
         let thisName = extensionIndex2 >= 0 ? key.substr(0, extensionIndex2) : key;
         let thisExtension = extensionIndex2 >= 0 ? key.substr(extensionIndex2) : '';

         return thisName.search(copyNameMore) >= 0 && (extensionIndex2 < 0 || isEqual(extension, thisExtension));
      }
      return false;
   });

   if (matchingFiles?.length > 0) {
      let index = matchingFiles?.length;
      newName += `(${index})` + extension;

      while (!isNameUnique(newName, files)) {
         index += 1;
         newName = `${justName} Copy(${index})` + extension;
      }
   } else {
      newName += extension;
   }

   return newName;
};

/**
 * Indicates if the name unique in the directory.
 * @param file The file with the name to check for unique.
 * @param files The file list to check.
 * @returns {boolean} True if the name is unique.
 */
export const isNameUnique = (file, files) => {
   const copyNameMore = '^' + escapeRegExp(file?.name) + '$';
   const matchingFiles = filter(files, (theFile) => {
      if (theFile?.lastModified !== file?.lastModified && theFile?.name?.length > 0) {
         return theFile?.name.search(copyNameMore) >= 0;
      }
      return false;
   });
   return matchingFiles?.length <= 0;
};

// /**
//  * Convert the image into the api call to get a "local" image to avoid cross domain issues.
//  * @param image The image to convert.
//  * @return {string|undefined|*} The relative image location.
//  */
// export const convertImageToWrapper = (image) => {
//    if (image) {
//       if (process.env.NODE_ENV === 'production') {
//          //https://(bucketName).s3.us-east-2.amazonaws.com/(key)
//          // eslint-disable-next-line
//          const regex = /https:\/\/([^.]*)[^\/]*.amazonaws.com\/(.*)/;
//          const found = image.match(regex);
//
//          if (found && found.length > 0) {
//             const bucketName = found[1];
//             const key = found[2];
//             return `/image?bucket=${bucketName}&key=${key}`;
//          }
//          console.log('Image URL could not be parsed', image);
//          return image;
//       } else {
//          return image;
//       }
//    }
//    return undefined;
// };

// export function downloadBlob(blob, filename) {
//    const url = URL.createObjectURL(blob);
//    const a = document.createElement('a');
//    a.href = url;
//    a.download = filename || 'download';
//    const clickHandler = () => {
//       setTimeout(() => {
//          URL.revokeObjectURL(url);
//          a.removeEventListener('click', clickHandler);
//       }, 150);
//    };
//    a.addEventListener('click', clickHandler, false);
//    a.click();
//    return a;
// }

export const compare = (a, b, selected) => {
   function compareStrings(a, b) {
      a = typeof a === 'string' ? a.toLowerCase() : a;
      b = typeof b === 'string' ? b.toLowerCase() : b;

      // Return either 1 or -1 to indicate a sort priority
      if (a > b) {
         return 1;
      }
      if (a < b) {
         return -1;
      }
      // returning 0, undefined or any false value will use subsequent sorts or
      // the index as a tiebreaker
      return 0;
   }

   const indexA = findIndex(selected, (select) => select === a.id);
   const indexB = findIndex(selected, (select) => select === b.id);

   if (indexA >= 0) {
      if (indexB >= 0) {
         return compareStrings(a?.name, b?.name);
      }
      return -1;
   } else if (indexB >= 0) {
      return 1;
   } else {
      return compareStrings(a?.name, b?.name);
   }
};

/**
 * Cast a value to an array.
 *
 * NOTE:
 *    The only difference from LoDash.castArray is if value is undefined, it returns [] instead of [undefined].
 * @param value The value to cast
 * @return {*} An array
 */
export const castArray = (value) => {
   return castArrayLD(value === undefined ? [] : value);
};

/**
 * Cast an array or value to a value. The first value in the array will be returned if there are multiple values.
 *
 * @param arrayOrValue The array or value to cast
 * @return {*} a value
 */
export const castValue = (arrayOrValue) => {
   return isArray(arrayOrValue) ? arrayOrValue?.[0] : arrayOrValue;
};

/**
 * Creates a new array concatenating array with any additional arrays and/or values.
 *
 * NOTE:
 *    The only difference from LoDash.concat is if a value is undefined, it doesn't concat [undefined].
 * @param values The values to concat
 * @return {*} An array
 */
export const concat = (...values) => {
   const nonEmptyValues = filter(values, (value) => !isEmpty(value));
   return nonEmptyValues?.length > 0 ? concatLD(...nonEmptyValues) : undefined;
};

const DEFAULT_SORT_OPTIONS = {sensitivity: 'base'};

export const sortObjectArray = (array, keys, options = DEFAULT_SORT_OPTIONS) => {
   if (array?.length > 0 && keys?.length > 0) {
      array.sort(sortObjectBy(keys, options));
   }
   return [];
};
export const sortObjectBy =
   (keys, options = DEFAULT_SORT_OPTIONS) =>
   (a, b) => {
      if (keys?.length > 0) {
         if (isObject(a) && isObject(b)) {
            for (const key of keys) {
               const aProperty = a[key];
               const bProperty = b[key];
               if (!isNil(aProperty) && !isNil(bProperty)) {
                  if (typeof aProperty === 'string') {
                     const result = aProperty.localeCompare(bProperty, undefined, options);
                     if (result !== 0) {
                        return result;
                     }
                  } else if (aProperty !== bProperty) {
                     if (typeof aProperty === 'boolean') {
                        return aProperty ? 1 : -1;
                     } else {
                        return aProperty < bProperty ? -1 : 1;
                     }
                  }
               } else if (isNil(aProperty) !== isNil(bProperty)) {
                  // Place undefined elements at the end of the array
                  return isNil(aProperty) ? 1 : -1;
               }
            }
         } else {
            return !isObject(a) && !isObject(b) ? 0 : isObject(a) ? -1 : 1;
         }
      } else if (process.env.NODE_ENV !== 'production') {
         console.log('Keys was an empty array');
      }
   };

/**
 * Join the array with a separator and add the last item after the last separator. For example: ['1', '2', '3'] would
 * become "1, 2 and 3".
 *
 * @param array The array to join.
 * @param separator The separator for all elements but the last.
 * @param lastSeparator The separator between the other elements and the last element.
 * @return {*|string}
 */
export const join = (array, separator = ', ', lastSeparator = ' and ') => {
   if (array?.length > 0) {
      const theArray = [...array];
      const last = theArray.pop();

      if (theArray.length === 0 && last) {
         return last;
      }
      return _join(theArray, separator) + lastSeparator + last;
   }
   return '';
};

export function downloadBlob(blob, filename) {
   const url = URL.createObjectURL(blob);
   const a = document.createElement('a');
   a.href = url;
   a.download = filename || 'download';
   const clickHandler = () => {
      setTimeout(() => {
         URL.revokeObjectURL(url);
         a.removeEventListener('click', clickHandler);
      }, 150);
   };
   a.addEventListener('click', clickHandler, false);
   a.click();
   return a;
}

export function getMailtoUrl(intl, subjectKey = 'contact.issue.subject', contactName, contactEmail) {
   const subject = formatMessage(intl, subjectKey);
   const body = formatMessage(intl, 'contact.body', undefined, {
      url: window.location.href,
      contactName: contactName || '',
   });

   var args = [];
   if (subjectKey && typeof subject !== 'undefined') {
      args.push('subject=' + encodeURIComponent(subject));
   }
   if (contactName && typeof body !== 'undefined') {
      args.push('body=' + encodeURIComponent(body));
   }

   let mailUrl = 'mailto:' + encodeURIComponent(CONTACT_EMAIL);
   if (args.length > 0) {
      mailUrl += '?' + args.join('&');
   }
   return mailUrl;
}

/**
 * Remove the properties of the object that don't have a value.
 * @param item The item from which to remove properties.
 * @return {*} The new item with the properties removed. Original item is unchanged.
 */
export function removeEmptyProperties(item) {
   const newItem = {...item};

   for (const itemKey in item) {
      if (item[itemKey] === undefined || item[itemKey] === null) {
         delete newItem[itemKey];
      }
   }
   return newItem;
}

import {useMutation} from '@apollo/client';
import {isEqual} from 'lodash';
import uniqueId from 'lodash/uniqueId';
import {useCallback, useState, useRef, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {useSetRecoilState} from 'recoil';
import {errorState} from '../../components/ErrorStateSnackbar';
import {cacheDelete, cacheAdd, cacheUpdate} from '../../utils/DataUtil';
import {formatMessage, resultOf} from '../../utils/Utils';
import useProgress from '../useProgress';

export const CREATE_UPDATE_ACTION = 'update';
export const DELETE_ACTION = 'delete';
export const UNDELETE_ACTION = 'undelete';

/**
 * Hook for useMutation that updates the cache for add and delete queries. Update mutations should automatically update
 * cache without this update.
 *
 * NOTE:
 * 1) Assumes that the result of the mutation only has a single property. (e.g. {data: {operators: {...}}})
 * 2) Updates ONLY the FIRST property in an updateQuery. The first property is assumed to be a list and adds the result
 *    property to the list. Other properties in the original query are copied to the updated cache item.
 *
 * Reviewed:
 *
 * @param mutation The graphql mutation.
 *    typeKey - The localization key for the type of the object
 *    actionType - The localization key for the action type (e.g. create, update, delete).
 * @param [options] The options for the mutation.
 * @param [showLoading Indicates if the progress should be shown.
 * @param [useOptimisticResponse] Indicates if the optimistic response should be added to the options.
 * @param [isUpdate] Indicates if the cache needs to be updated. Update handles both update and add. Should be true if
 *    updateVariables will be used and the mutation is not Delete or if the updateCache must always be called.
 * @param defaultValueProp The default values for the item being updated or created.
 * @return
 */
export default function useMutationFHG(
   mutation,
   options,
   showLoading,
   useOptimisticResponse = true,
   isUpdate = false,
   defaultValueProp
) {
   const theUniqueId = useRef(uniqueId()).current;
   const intl = useIntl();
   const [, /*Unused*/ setProgress] = useProgress(theUniqueId);

   const setErrorState = useSetRecoilState(errorState);

   const [lastMessage, setLastMessage] = useState('');

   useEffect(() => {
      return () => {
         setProgress(false);
      };
   }, [setProgress]);

   const [mutationFunction, result] = useMutation(mutation?.mutation, options);

   useEffect(() => {
      if (result?.error) {
         const type = formatMessage(intl, mutation?.typeKey);
         const action = formatMessage(intl, mutation?.actionKey || CREATE_UPDATE_ACTION);
         const errorMessage = formatMessage(intl, 'action.error', undefined, {type, action});

         if (errorMessage !== lastMessage) {
            console.log(result?.error);
            setLastMessage(errorMessage);
            setErrorState({error: result?.error, errorMessage, errorKey: undefined});
         }
      } else if (lastMessage !== undefined) {
         setLastMessage(undefined);
      }
   }, [result?.error, setErrorState, lastMessage, intl, mutation?.actionKey, mutation?.typeKey]);

   useEffect(() => {
      if (showLoading) {
         setProgress(result?.loading);
      }
   }, [result?.loading, setProgress, showLoading]);

   /**
    * @param options The useMutation options
    * @param updateVariables The variables to pass to cacheAdd or cacheUpdate to send to the queries.
    * @param isNew Indicates if the item created is new or existing.
    * @param defaultValues The item's default values to use for the optimistic response.
    * @type {function(*, *, *, {}=): Promise<FetchResult<any>>}
    */
   const mutationFunctionFHG = useCallback(
      (options, updateVariables, isNew, defaultValues = {}) => {
         if (useOptimisticResponse) {
            if (!mutation.resultType) {
               if ((mutation.resultType = mutation?.mutation?.definitions?.length > 1)) {
                  mutation.resultType = mutation?.mutation?.definitions?.[1]?.typeCondition?.name?.value;
               } else {
                  mutation.resultType = mutation?.mutation?.definitions?.[0].selectionSet.selections[0].name.value;
               }
            }
            if (!mutation.path) {
               if (mutation?.mutation?.definitions?.[0]?.selectionSet?.selections?.[0]?.alias) {
                  mutation.path = mutation?.mutation?.definitions?.[0]?.selectionSet?.selections?.[0]?.alias?.value;
               } else {
                  mutation.path = mutation?.mutation?.definitions?.[0].selectionSet.selections[0].name.value;
               }
            }
            if (!options?.optimisticResponse && options?.variables && mutation.path && mutation.resultType) {
               if (mutation.actionKey === DELETE_ACTION) {
                  options.optimisticResponse = {[mutation.path]: 1};
               } else if (mutation.resultType) {
                  options.optimisticResponse = {
                     __typename: 'Mutation',
                     [mutation.path]: {
                        __typename: mutation.resultType,
                        ...resultOf(defaultValueProp, options, defaultValues),
                        ...options.variables,
                        isDeleted: false,
                     },
                  };
               }
               if (!options?.update) {
                  if (isUpdate) {
                     options.update = cacheUpdate(mutation, options.variables?.id, updateVariables);
                  } else if (isNew) {
                     options.update = cacheAdd(mutation, updateVariables);
                  } else if (mutation.actionKey === DELETE_ACTION) {
                     options.update = cacheDelete(mutation, options.variables.id, undefined, updateVariables);
                  }
               }
            } else if (!!options?.optimisticResponse) {
               const autoGenerated = {
                  __typename: 'Mutation',
                  [mutation.path]: {
                     __typename: mutation.resultType,
                     ...defaultValues,
                     ...options.variables,
                     isDeleted: false,
                  },
               };
               const isEQUAL = isEqual(options?.optimisticResponse, autoGenerated);
               console.log(
                  'optimisticResponse - custom response will override',
                  options?.optimisticResponse,
                  autoGenerated,
                  'is equal = ',
                  isEQUAL
               );
            } else if (!options?.variables) {
               console.log('optimisticResponse - variables not set', options?.variables);
            } else if (!mutation.path) {
               console.log('optimisticResponse - path not defined in mutation', mutation.path);
            } else if (!mutation.resultType) {
               console.log('optimisticResponse - resultType not defined in mutation', mutation.resultType);
            }
         }

         return mutationFunction(options);
      },
      [defaultValueProp, isUpdate, mutation, mutationFunction, useOptimisticResponse]
   );

   return [mutationFunctionFHG, result];
}

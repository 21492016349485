import gql from 'graphql-tag';

export const JURISDICTION_FRAGMENT = gql`
   fragment JurisdictionInfo on Jurisdiction {
      id
      name
      abbreviation
   }
`;

export const LOCAL_JURISDICTION_FRAGMENT = gql`
   fragment localJurisdictionInfo on LocalJurisdiction {
      id
      name
      jurisdictionId
   }
`;

// export const TYPE_FRAGMENT = gql`
//    fragment typeInfo on Type {
//       id
//       name
//       category
//    }
// `;

export const USER_FRAGMENT = gql`
   fragment userInfo on User {
      id
      username
      contactName
      name: contactName
      email
      roleId
      phonePrimary
      phoneSecondary
      addressLineOne
      addressLineTwo
      cityId
      stateId
      zipCode
      noOfSearches
      isDeleted
      eulaConfirmed
   }
`;

export const USER_SEARCH_FRAGMENT = gql`
   fragment userSearchInfo on UserSearch {
      id
      type
      jurisdictionIdList
      localJurisdictionIdList
      codeTypeIdList
      createdDateTime
      #TODO add back when supported by server
      #      modificationText
      jurisdictions {
         id
         name
      }
      localJurisdictions {
         id
         name
      }
      codeTypes {
         id
         name
      }
   }
`;

export const USER_FAVOURITE_FRAGMENT = gql`
   fragment userFavouriteInfo on UserFavourite {
      id
      resourceType
      resourceId
      createdDateTime
   }
`;

export const CITY_FRAGMENT = gql`
   fragment CityInfo on City {
      id
      name
      label: name
      isDeleted
   }
`;

export const STATE_FRAGMENT = gql`
   fragment StateInfo on State {
      id
      name
      label: name
      abbreviation
   }
`;

// export const AUTHORITY_FRAGMENT = gql`
//    fragment AuthorityInfo on Authority {
//       id
//       name
//       localJurisdictionId
//       addressId
//       mailingAddressId
//       codeTypeIdList
//    }
// `;

export const AUTHORITY_FRAGMENT = gql`
   fragment authorityInfo on Authority {
      id
      name
      localJurisdictionId
      addressId
      mailingAddressId
      codeTypeIdList
      codeTypeList {
         id
         name
      }
   }
`;

export const CODE_TITLE_FRAGMENT = gql`
   fragment codeTitleInfo on CodeTitle {
      id
      name
      codeTypeId
      updatedDateTime
   }
`;

export const CODE_EDITION_FRAGMENT = gql`
   fragment codeEditionInfo on CodeEdition {
      id
      name
      codeTitleId
   }
`;

export const ADDRESS_FRAGMENT = gql`
   fragment addressInfo on Address {
      id
      addressLineOne
      addressLineTwo
      cityId
      stateId
      zipCode
   }
`;

export const CONTACT_FRAGMENT = gql`
   fragment contactInfo on Contact {
      id
      name
      email
      phone
      fax
      contactTypeId
   }
`;

export const WEBSITE_FRAGMENT = gql`
   fragment websiteInfo on Website {
      id
      name
      websiteTypeId
      authorityId
      url
   }
`;

export const CODE_TYPE_FRAGMENT = gql`
   fragment codeTypeInfo on CodeType {
      id
      name
      label: name
      isGenericType
      genericTypeId
      genericType {
         id
         name
      }
      types {
         id
         name
      }
   }
`;

export const CODE_INFORMATION_FRAGMENT = gql`
   fragment codeInformationInfo on CodeInformation {
      id
      name
      codeEditionId
      localJurisdictionId
      comment
      effectiveDate
      pendingEffectiveDate
      attachmentFileData {
         id: fileHash
         fileFilename
         fileBucket
         fileS3
         fileKey
         fileUpdateDateTime
      }
      createdDateTime
   }
`;

export const CODE_INFORMATION_LX_FRAGMENT = gql`
   fragment codeInformationLxInfo on CodeInformation {
      id
      name
      codeEditionId
      codeEdition {
         id
         name
         codeTitle {
            id
            name
         }
      }
      codeMods {
         id
      }
      localJurisdictionId
      comment
      effectiveDate
      pendingEffectiveDate
      pendingModificationCount
      createdDateTime
   }
`;

const CODE_INFORMATION_PARTIAL_SEARCH_FRAGMENT = gql`
   fragment codeInformationPartialSearchInfo on CodeInformation {
      localJurisdictionId
      localJurisdiction {
         id
         name
         jurisdiction {
            id
            name
            abbreviation
         }
      }
      codeEditionId
      codeEdition {
         id
         name
         codeTitleId
         codeTitle {
            id
            name
            codeTypeId
            codeType {
               id
               name
            }
         }
      }
      codeMods {
         id
         title
         requirement
         label: requirement
         requirementDesc1
         originalText
         modifiedText
         comment
         effectiveDate
         isPending
      }
   }
`;

export const CODE_INFORMATION_SEARCH_FRAGMENT = gql`
   fragment codeInformationSearchInfo on CodeInformation {
      pendingModificationCount
      isCurrentCode
      currentCodeInformation {
         ...codeInformationPartialSearchInfo
      }
      ...codeInformationPartialSearchInfo
   }
   ${CODE_INFORMATION_PARTIAL_SEARCH_FRAGMENT}
`;

export const CODE_MODIFICATION_FRAGMENT = gql`
   fragment codeModificationInfo on CodeModification {
      id
      comment
      effectiveDate
      codeInformationId
      requirement
      label: requirement
      requirementDesc1
      statusId
      title
      isPending
      originalText
      modifiedText
      updatedDateTime
   }
`;

export const CODE_MODIFICATION_PATH_FRAGMENT = gql`
   fragment codeModificationPathInfo on CodeModification {
      id
      isPending
      effectiveDate
      createdDateTime
      updatedDateTime
      codeInformationId
      comment
      codeInfo {
         id
         name
         localJurisdictionId
         localJurisdiction {
            id
            jurisdictionId
            jurisdiction {
               id
               name
            }
         }
         codeEditionId
         codeEdition {
            id
            name
            codeTitleId
            codeTitle {
               id
               name
               codeTypeId
            }
         }
      }
   }
`;

export const REPORT_TEMPLATE_FRAGMENT = gql`
   fragment reportTemplateInfo on ReportTemplate {
      id
      name
      subject
      fromName
      fromEmail
      toEmail
      type
      scheduledDate
      autoSchedule
      startDate
      html
      lastSentDate
      hour
      minute
      days
      customInterval
      customIntervalType
   }
`;

export const REPORT_HISTORY_FRAGMENT = gql`
   fragment reportHistoryInfo on ReportHistory {
      id
      name
      subject
      fromName
      fromEmail
      toEmail
      html
      sentDate
      reportTemplateId
   }
`;

export const AUTHORITY_SEARCH_FRAGMENT = gql`
   fragment authoritySearchInfo on Authority {
      id
      name
      addressId
      address {
         id
         name
         addressLineOne
         addressLineTwo
         city {
            id
            name
         }
         state {
            id
            name
            abbreviation
         }
         zipCode
      }
      mailingAddressId
      mailingAddress {
         id
         name
         addressLineOne
         addressLineTwo
         city {
            id
            name
         }
         state {
            id
            name
         }
      }
      contacts {
         id
         name
         email
         phone
         fax
         jobTitle
      }
      websites {
         id
         name
         url
      }
      codeTypeIdList
      codeTypeList {
         id
         name
      }
      localJurisdictionId
      localJurisdiction {
         id
         jurisdictionId
         jurisdiction {
            id
            name
         }
      }
   }
`;

// noinspection JSCheckFunctionSignatures

import {indexOf} from 'lodash';
import {useEffect} from 'react';
import {atom, useRecoilState} from 'recoil';
import {ADMIN_GROUP} from '../../../Constants';
import {USER_BY_COGNITO_SUB_QUERY} from '../../../data/QueriesGL';
import useLazyQueryFHG from '../../hooks/data/useLazyQueryFHG';

export const userState = atom({
   key: 'userGroupsStateKey',
   default: {username: '', groups: [], signOut: undefined, sub: undefined, isAdmin: undefined},
});

export default function AuthenticatedUser({authState, onAuthenticated, children}) {
   const [userStateData, setUserState] = useRecoilState(userState);

   const [loadData] = useLazyQueryFHG(USER_BY_COGNITO_SUB_QUERY, undefined, 'user.type');

   useEffect(() => {
      if (authState) {
         const groups = authState?.user?.signInUserSession?.idToken?.payload?.['cognito:groups'] ?? [];
         const sub = authState?.user?.attributes?.sub;
         const isAdmin = indexOf(groups, ADMIN_GROUP) >= 0;

         // eslint-disable-next-line
         setUserState({username: authState?.user?.username, groups, signOut: authState.signOut, sub, isAdmin});
         onAuthenticated?.(authState);

         if (userStateData?.username !== authState?.user?.username) {
            (async () => {
               const result = await loadData({variables: {cognitoSub: sub}});
               const user = result?.data?.users?.[0];

               if (user) {
                  setUserState((userState) => ({
                     ...userState,
                     contactName: user?.contactName || authState?.user?.username,
                     email: user?.email,
                  }));
               } else {
                  setUserState((userState) => ({
                     ...userState,
                     contactName: authState?.user?.username,
                  }));
               }
            })();
         }
      }
   }, [authState, loadData, onAuthenticated, setUserState, userStateData?.username]);

   if (authState) {
      return children;
   } else {
      return null;
   }
}

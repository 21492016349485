import '@aws-amplify/ui-react/styles.css';
import CssBaseline from '@mui/material/CssBaseline';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {Amplify} from 'aws-amplify';
import {SnackbarProvider} from 'notistack';
import React, {useEffect, useState} from 'react';
import {IntlProvider} from 'react-intl';
import {makeStyles} from 'tss-react/mui';
import './App.css';
import ErrorBoundary from './components/ErrorBoundary';
import Eula from './components/Eula';
import {authenticatorTheme} from './components/theme/authenticatorTheme';
import {FILE_BUCKET} from './Constants';
import awsconfig from './environment/aws-exports';
import awsProductionConfig from './environment/aws-production-exports';
import AuthenticatorFHG from './fhg/components/security/Authenticator';
import Main from './pages/Main';
import moment from 'moment-timezone';

const useStyles = makeStyles({name: 'AppStyles'})({
   frameStyle: {
      '@media print': {
         height: '100%',
      },
      '@media screen': {
         height: `100vh`,
      },
      overflow: 'hidden',
      backgroundColor: '#FCFEFE',
      '& > div': {
         height: '100%',
      },
   },
});

const config = process.env.REACT_APP_POOL === 'production' ? awsProductionConfig : awsconfig;
Amplify.configure(config);
Amplify.configure({
   Storage: {
      bucket: FILE_BUCKET, //REQUIRED -  Amazon S3 bucket
      region: 'us-east-2',
      customPrefix: {
         public: '',
         protected: '',
         private: '',
      },
   },
   Analytics: {
      // OPTIONAL - disable Analytics if true
      disabled: true,
   },
});

/**
 * Load the messages for the locales.
 * CAVEAT: This is required to code splitting to work.
 */
const messageLoader = {
   en: () => import('./messages/en-US'),
};

const formats = {
   number: {
      USD: {
         style: 'currency',
         currency: 'USD',
      },
   },
};

/**
 * App component. Responsible for initializing AWS, GraphQL and Intl (localization). App can be displayed without
 * authorization. Main is displayed when authorized.
 *
 * @return {JSX.Element|null}
 * @constructor
 */
export default function App() {
   const {classes} = useStyles();
   const [messages, setMessages] = useState({});

   useEffect(() => {
      messageLoader.en().then((messages) => {
         setMessages(messages);
      });
   }, []);

   if (Object.keys(messages).length > 0) {
      return (
         <ErrorBoundary>
            <IntlProvider messages={messages} locale={'en'} formats={formats}>
               <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
                  <SnackbarProvider maxSnack={3} anchorOrigin={{horizontal: 'center', vertical: 'top'}}>
                     <div className={classes.frameStyle}>
                        <AuthenticatorFHG theme={authenticatorTheme}>
                           <Eula />
                           <CssBaseline />
                           <Main />
                        </AuthenticatorFHG>
                     </div>
                  </SnackbarProvider>
               </LocalizationProvider>
            </IntlProvider>
         </ErrorBoundary>
      );
   } else {
      return null;
   }
}

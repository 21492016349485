import {useQuery} from '@apollo/client';
import {uniqWith} from 'lodash';
import {isEqual} from 'lodash';
import {omit} from 'lodash';
import {useState} from 'react';
import {useCallback} from 'react';
import {useMemo} from 'react';
import {useEffect} from 'react';
import {useSetRecoilState} from 'recoil';
import {errorState} from '../../components/ErrorStateSnackbar';
import useMessage from '../useMessage';

export const requeryState = {};

export default function useQueryFHG(query, options) {
   const [isFetchMore, setIsFetchMore] = useState(false);

   const type = useMessage(query?.typeKey, 'Unknown');

   const setErrorState = useSetRecoilState(errorState);

   const result = useQuery(query?.query, options);

   useEffect(() => {
      if (query?.queryPath) {
         const variables = omit(options?.variables, ['offset', 'limit']);
         const list = requeryState[query?.queryPath] || [];

         requeryState[query?.queryPath] = uniqWith(
            [...list, {query: query?.query, variables, queryPath: query?.queryPath}],
            isEqual
         );
      }
   }, [options, query]);

   useEffect(() => {
      if (result?.error) {
         console.log('Error type', query.typeKey);
         console.log(result?.error, result?.error.stackTrace);
         setErrorState({error: result?.error, errorKey: 'load.error', values: {type, message: result?.error.message}});
      }
   }, [result?.error, setErrorState, query?.typeKey, type]);

   /**
    * fetchMore that handles loading which result doesn't do correctly.
    * @type {(function(*): Promise<void>)|*}
    */
   const fetchMoreWithLoading = useCallback(
      async (options) => {
         setIsFetchMore(true);
         const moreResult = await result.fetchMore(options);
         setIsFetchMore(false);
         return moreResult;
      },
      [result]
   );

   return useMemo(() => {
      const currentResult = {...result, fetchMore: fetchMoreWithLoading};

      // Force the loading to true and data to undefined since it isn't handled right for fetchMore.
      if (isFetchMore) {
         currentResult.loading = true;
      }
      return [result.data || {}, currentResult, result.refetch];
   }, [result, fetchMoreWithLoading, isFetchMore]);
}

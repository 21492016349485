// import {isNumber} from 'lodash';
import {castArray, find, findIndex, get, has, isArray, isObject} from 'lodash';
import {removeOne} from './Utils';

/**
 * Update the cache for the list of queries. The query list will have the query, the variables, and the
 * queryPath(optional). If the queryPath isn't specified, the mutationPath will be used
 *
 * @param {object}mutationQuery The mutation for which the cache needs to be updated.
 * @param id of the item to update or the predicate object to find the object to update.
 * @param {object}[variables] The variables for the updateQuery function. If the updateQuery is not a function the
 *   variables will be ignored.
 * @return {function: void} The function for update.
 */
export const cacheUpdate = (mutationQuery, id, variables) => {
   const queryList =
      typeof mutationQuery.updateQueries === 'function'
         ? mutationQuery.updateQueries(variables)
         : mutationQuery.updateQueries;
   const useQueryList = castArray(queryList);

   if (id !== undefined) {
      return (proxy, {data}) => {
         const mutationPath = mutationQuery.path;

         for (const queryItem of useQueryList) {
            const {query, variables, path: queryPath = mutationPath} = queryItem;
            let resultData = get(data, mutationPath);

            // Did the mutation return any data?
            if (resultData) {
               // Is the data coming back from the mutation an array?
               if (isArray(resultData)) {
                  //If one element use the single element.
                  if (resultData?.length === 1) {
                     resultData = resultData[0];
                  } else {
                     console.log('resultData from the mutation is an array with more than one item.', resultData);
                  }
               }
               try {
                  const cachedData = proxy.readQuery({query, variables});
                  if (cachedData) {
                     const itemIndex = findIndex(cachedData[queryPath], isObject(id) ? id : {id});
                     let arr;

                     if (itemIndex >= 0) {
                        arr = [...cachedData[queryPath]];
                        arr[itemIndex] = resultData;
                     } else {
                        arr = [...(cachedData[queryPath] || []), resultData];
                     }
                     proxy.writeQuery({query, variables, data: {...cachedData, [queryPath]: arr}});
                  } else {
                     if (process.env.NODE_ENV !== 'production') {
                        console.log('Failed to update cache.', variables);
                     }
                  }
               } catch (e) {
                  if (process.env.NODE_ENV !== 'production') {
                     console.log('Failed to update cache.', e);
                  }
               }
            } else {
               console.log('Could not get the result from the mutation data. Check the mutation path', mutationPath);
            }
         }
      };
   } else {
      return cacheAdd(mutationQuery, variables);
   }
};

/**
 * Add the new item to the cache for the list of queries. The query list will have the query, the variables, and the
 * queryPath(optional). If the queryPath isn't specified, the mutationPath will be used
 *
 * @param mutationQuery The mutation for which the cache needs to have the new item added.
 * @param variables The variables for the update queries.
 * @param isArray Indicates if the result data to be written back is an array
 * @return {function(*): void} The function to update the cache.
 */
export const cacheAdd = (mutationQuery, variables, isArray = false) => {
   const queryList =
      typeof mutationQuery.updateQueries === 'function'
         ? mutationQuery.updateQueries(variables)
         : mutationQuery.updateQueries;
   const useQueryList = castArray(queryList);

   return (proxy, {data}) => {
      const mutationPath = mutationQuery.path;
      for (const queryItem of useQueryList) {
         const {query, variables} = queryItem;
         let {path: queryPath = mutationPath} = queryItem;
         let newArray;

         const resultData = get(data, mutationPath);
         if (resultData) {
            // Read the data from our cache for this query.
            const cachedData = proxy.readQuery({query, variables});

            if (cachedData) {
               // Use the query path from the query definition alias or the cachedData.
               if (!queryPath) {
                  queryPath =
                     query.definitions[0].selectionSet.selections[0].alias.value || Object.keys(cachedData)?.[0];
                  if (process.env.REACT_APP_POOL !== 'production') {
                     console.log('cacheAdd - queryPath not set. Using the value ', queryPath);
                  }
               }
               // Check for missing or incorrect queryPath.
               if (process.env.REACT_APP_POOL !== 'production' && !has(cachedData, queryPath)) {
                  console.log(
                     'cacheAdd - updateQuery does not have queryPath - ' + queryPath + '.',
                     query?.definitions?.[0]?.name?.value
                  );
               }
               // Write our data back to the cache with the new item in it
               if (isArray) {
                  newArray = [...(cachedData[queryPath] || []), ...resultData];
               } else {
                  newArray = [...(cachedData[queryPath] || []), resultData];
               }
               const newData = {...cachedData, [queryPath]: newArray};
               proxy.writeQuery({query, variables, data: newData});
            } else {
               console.log('cacheAdd - Query not found', query?.definitions?.[0]?.name?.value, variables);
            }
         } else {
            console.log('Could not get the result from the mutation data. Check the mutation path', mutationPath);
         }
      }
   };
};

/**
 * Delete the item add the uuid from the cache for the list of queries. The query list will have the query, the
 * variables, and the queryPath(optional). If the queryPath isn't specified, the path will be used.
 *
 * @param mutationQuery The mutation for which the cache needs to have the new item added. * @param id The id of the
 *   item to be deleted.
 * @param id
 * @param [deleteIdKey] The property that holds the id for the deleted object
 * @param {object}[variables] The variables for the updateQuery function. If the updateQuery is not a function the
 *   variables will be ignored.
 * @return {function(*): void} function to update cache for delete.
 */
export const cacheDelete = (mutationQuery, id, deleteIdKey = 'id', variables) => {
   const queryList =
      typeof mutationQuery.updateQueries === 'function'
         ? mutationQuery.updateQueries(variables)
         : mutationQuery.updateQueries;
   const useQueryList = castArray(queryList);

   return (proxy) => {
      const mutationPath = mutationQuery.path;

      for (const queryItem of useQueryList) {
         const {query, variables, path: queryPath = mutationPath} = queryItem;

         const cachedData = proxy.readQuery({query, variables});
         if (cachedData) {
            const itemIndex = findIndex(cachedData[queryPath], {[deleteIdKey]: id});
            if (itemIndex >= 0) {
               const modifiedList = removeOne([...cachedData[queryPath]], itemIndex);
               proxy.writeQuery({
                  query,
                  variables,
                  data: {...cachedData, [queryPath]: modifiedList.length > 0 ? modifiedList : null},
               });
            }
         } else {
            console.log('cacheDelete - Query not found', query?.definitions?.[0]?.name?.value, variables);
         }
      }
   };
};

/**
 * Undelete the item add the uuid from the cache for the list of queries. The query list will have the query, the
 * variables, and the queryPath(optional). If the queryPath isn't specified, the path will be used.
 *
 * @param queryList the list of queries to delete the item at uuid. (e.g. {query, variables, queryPath})
 * @param item the item to be undeleted.
 * @param [path] Property name resulting object being updated.
 * @return {function(*): void} function to update cache for an undelete.
 */
export const cacheUndelete = (queryList, item, path) => {
   const useQueryList = castArray(queryList);

   return (proxy) => {
      for (const queryItem of useQueryList) {
         const {query, variables, queryPath = path} = queryItem;

         const cachedData = proxy.readQuery({query, variables});
         if (cachedData) {
            let newData;
            // Force the item to be undeleted because undelete doesn't return the modified item.
            const useItem = {...item, isDeleted: false};

            if (isArray(cachedData[queryPath])) {
               const newArray = [...(cachedData[queryPath] || []), useItem];
               newData = {...cachedData, [queryPath]: newArray};
            } else {
               newData = {[queryPath]: useItem};
            }
            proxy.writeQuery({query, variables, data: newData});
         } else {
            console.log('cacheUndelete - Query not found', query?.definitions?.[0]?.name?.value, variables);
         }
      }
   };
};

export const getDataItem = (data, type) => {
   if (isArray(data?.[type]) && data?.[type]?.length === 1) {
      return data?.[type]?.[0];
   } else {
      return data?.[type];
   }
};

/**
 *
 * @param id lookup ID
 * @param options list of options in which to find the id
 * @param key name of field to return.
 * @param defaultValue Value if the item and field aren't found
 * @returns {string|*|string} Value of the field of the item if found
 */
export const getLookupValue = (id, options, key = 'name', defaultValue = '') => {
   if (id !== undefined && options?.length > 0) {
      const item = find(options, {id}) || {};
      return item?.[key] || defaultValue;
   }
   return defaultValue;
};

// const DEFAULT_DATE_FORMATTER = new Intl.DateTimeFormat();

/**
 * Format a date only string without any timezone. Example: '2022-01-23' and NOT: '2022-08-03T05:07:20.144Z'. The date
 * will be converted in the current time zone. So as the example before will return 01/23/2022.
 *
 * @param dbDateValue The date to format. Can either be a date string or an object with date string at
 *   dbDateValue.value.
 * @param format The optional format for the date. If not given will default to the default format of
 *   Intl.DateTimeFormat().
 * @returns {string|*} The formatted date string.
 */
/*export function formatNoTimezoneDate(dbDateValue, format) {
   let formatter;
   let date;

   if (format && Object.keys(format)?.length > 0) {
      formatter = new Intl.DateTimeFormat(undefined, format);
   } else {
      formatter = DEFAULT_DATE_FORMATTER;
   }

   if (typeof dbDateValue === 'string') {
      date = new Date(dbDateValue);
   } else if (dbDateValue && 'value' in dbDateValue) {
      if (dbDateValue.value) {
         date = new Date(dbDateValue?.value);
      }
   }

   if (date) {
      const userTimezoneOffset = date.getTimezoneOffset() * 60000;
      date = new Date(date.getTime() + userTimezoneOffset);
      return formatter.format(date);
   }
   return '';
}*/

// /**
//  * Format a date or datetime string with timezone. Example: '2022-08-03T05:07:20.144Z' and NOT: '2022-01-23'. The date
//  * will be converted based on the embedded time zone. The example before will return 08/03/2022.
//  *
//  * @param dbDateValue The date to format. Can either be a date string or an object with date string at
//  *   dbDateValue.value.
//  * @param format The optional format for the date. If not given will default to the default format of
//  *   Intl.DateTimeFormat().
//  * @returns {string|*} The formatted date string.
//  */
// export function formatDate(dbDateValue, format) {
//    let formatter;
//
//    if (format && Object.keys(format)?.length > 0) {
//       formatter = new Intl.DateTimeFormat(undefined, format);
//    } else {
//       formatter = DEFAULT_DATE_FORMATTER;
//    }
//
//    if (typeof dbDateValue === 'string') {
//       return formatter.format(new Date(dbDateValue));
//    } else if (dbDateValue && 'value' in dbDateValue) {
//       if (dbDateValue.value) {
//          return formatter.format(new Date(dbDateValue?.value));
//       }
//    }
//
//    return '';
//
//    // moment(dbDateValue?.value || dbDateValue).format(format);
// }

// export function formatNumber(dbNumber, options) {
//    if (isNumber(dbNumber?.value)) {
//       return new Intl.NumberFormat(undefined, options).format(dbNumber?.value);
//    } else if (isNumber(dbNumber)) {
//       if (dbNumber === -0) {
//          return 0;
//       }
//       return new Intl.NumberFormat(undefined, options).format(dbNumber);
//    }
//    return '';
// }

// export function formatCurrency(dbNumber, format) {
//    const formatter = new Intl.NumberFormat(undefined, {
//       style: 'currency',
//       currency: 'USD',
//       ...format,
//    });
//
//    if (isNumber(dbNumber?.value)) {
//       return formatter.format(dbNumber?.value);
//    } else if (isNumber(dbNumber)) {
//       return formatter.format(dbNumber);
//    }
//    return '';
// }

// export function removeEmpty(obj) {
//    if (obj) {
//       return Object.fromEntries(
//          Object.entries(obj).filter(([_, v]) => v !== null && v !== undefined && v !== 'undefined')
//       );
//    }
//    return obj;
// }

// class FileData {
//    fileBucket;
//    fileFilename;
//    fileHash;
//    fileKey;
//    fileS3;
//    fileUpdateDateTime;
//
//    constructor(file) {
//       this.fileBucket = file?.fileBucket;
//       this.fileFilename = file?.fileFilename;
//       this.fileHash = file?.fileHash;
//       this.fileKey = file?.fileKey;
//       this.fileS3 = file?.fileS3;
//       this.fileUpdateDateTime = file?.fileUpdateDateTime;
//    }
// }

// export function fileConvertName(file) {
//    if (file instanceof File) {
//       return file.name;
//    } else if (typeof file === 'object') {
//       return file?.fileFilename;
//    }
//
//    console.log('File type is not recognized', file);
//    return 'Unknown';
// }

// export function fileConvertModified(file) {
//    if (file instanceof File) {
//       return moment(file.lastModifiedDate).format(DATE_FORMAT_KEYBOARD);
//    } else if (typeof file === 'object') {
//       return moment(file?.fileUpdateDateTime, DATE_DB_FORMAT).format(DATE_FORMAT_KEYBOARD);
//    }
//
//    console.log('File last modified date is not recognized', file);
//    return 'N/A';
// }

// export function fileConvertSize(file) {
//    if (file instanceof File) {
//       return file.lastModifiedD;
//    } else if (typeof file === 'object') {
//       return file?.fileFilename;
//    }
//
//    console.log('File type is not recognized', file);
//    return 'Unknown';
// }
